.ticketGroupContainer {
  width: 100%;
  justify-content: center;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  font-size: 15px;
  /* min-width: 1500px; */
}

.label {
  font-weight: bold;
}

.labelContainer {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.labelName {
  display: flex;
  flex-direction: row;
  text-wrap: nowrap;
  margin-right: 12px;
}

.homesiteList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.homesiteOption {
  padding: 4px 6px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.homesiteOption:hover {
  background-color: var(--color-input-shadow-focus);
}
.modal {
  width: 750px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.datesModal {
  width: 950px;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.dateMessage {
  margin: 12px;
}
.dateSelectors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px;
}
.purchaseDateSelectors {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}
.otherDateSelectors {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
}
.purchaseDates {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.datesLabel {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
}
.otherDatesLabel {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 28.5%;
}

.labelAndPickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.labelAndPickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.labelAndPickerContainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 57.5%;
}
.subLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}
.datePickerContainer > div {
  width: 100%;
  margin-right: 12px;
}
.timePickerContainer input {
  width: 90px;
}
.datePickerContainer,
.timePickerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.datePickerContainer input {
  height: 23px;
  width: 120px;
  font-size: 13.5px;
  background: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
}

.datePickerContainer button {
  font-size: 11px;
  font-weight: normal;
}

.timezoneText {
  font-size: 14.5px;
  min-width: 300px;
  margin-left: -10px;
}

.timezoneEdit {
  font-size: 14.5px;
  min-width: 300px;
}

.timezone {
  align-items: center;
  font-size: 12px !important;
  margin-left: 3px;
}

.timezoneSpacer {
  min-width: 170px;
}

.timezone div {
  font-weight: normal;
}

.timezone div button {
  width: 165px;
}

.timezone button svg {
  font-size: 20px;
  margin-right: -5px !important;
}
.startOn,
.endOn {
  display: grid;
  grid-template-columns: 10% 25% 30% 30%;
  align-items: center;
}
.startOn input,
.endOn input {
  height: 23px;
  width: 120px;
  font-size: 13.5px;
  background: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
}

.optionsListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
}

.optionsListHeader {
  display: grid;
  grid-template-columns: 9% 30% 60%; /* Checkbox, ID, Credit Type */
  width: 100%;
  background-color: var(--color-background-light);
  padding: 8px 8px 0 8px;
  font-weight: bold;
  text-align: center;
}

.optionsListHeader div:nth-child(2),
.optionsListHeader div:nth-child(3) {
  border-bottom: 2px solid var(--color-border); /* Add border to ID and Credit Type */
}

.optionsListBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 4px;
  overflow-y: auto; /* Enable scrolling for long lists */
}

.optionItemContainer {
  display: grid;
  grid-template-columns: 10% 30% 60%; /* Checkbox, ID, Credit Type */
  width: 100%;
  align-items: center;
  padding: 8px 0;
}

.optionItem {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 0;
}

.optionItem:nth-child(2),
.optionItem:nth-child(3) {
  border-bottom: 1px solid var(--color-border);
}

.header {
  width: 100%;
  background-color: var(--color-bg-accent);
  display: grid;
  align-items: center;
  grid-template-columns: 15% 50% 18% auto;
  grid-template-rows: 30px;
  padding: 0.75em 1em;
  border-radius: 5px;
}
.v2header {
  width: 100%;
  background-color: var(--color-bg-accent);
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: 30px;
  padding: 0.75em 1em;
  border-radius: 5px;
}
.subRemoveBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.subRemoveBtn {
  width: 75px;
  height: 30px;
}

.ticketsAvailable {
  font-weight: bold;
}

.ticketsAvailInput {
  position: relative;
  align-items: center;
}

.ticketsAvailInput input {
  height: 32px;
  text-align: right;
  align-items: center;
}

.numberSign {
  position: absolute;
  left: 10%;
  top: 20%;
  color: var(--color-input-placeholder);
}

.body {
  width: 100%;
  padding: 1em;
}

.purchaseEditContainer {
  display: grid;

  grid-template-columns: 15% 50% 20% 10% auto;
  white-space: wrap;
  align-items: center;
}

.purchaseLimitsSelect {
  width: 50%;
  min-width: 280px;
}

.ticketsPerInput input {
  width: 75%;
  height: 30px;
  font-size: 15px;
  margin-right: 1em;
}

.ticketsPerUser {
  font-weight: bold;
  margin-right: 1em;
}

.editButton {
  color: var(--color-button-white-text) !important;
  background-color: var(--color-button-white-bg);
  border-radius: 5px;
  border: 1px solid var(--color-button-white-border);
  padding: 3px;
  line-height: calc(1em + 2px);
  height: auto;
  width: 45px;
  font-weight: bold;
  transform: none;
}

.editButton:hover {
  background-color: var(--color-button-white-hover);
}

.ticketTableHeader {
  background-color: var(--color-card-header-bg);
  display: grid;
  grid-template-columns: 14% 19% 13.5% 19% 18% 10%;
  align-items: center;
  font-weight: bolder;
  padding: 0.75em 1em;
  margin-top: 1em;
  border-radius: 5px;
}
.ticketTableHeaderV1 {
  background-color: var(--color-card-header-bg);
  display: grid;
  /* grid-template-columns: 46% 40% 25%; */
  /* grid-template-columns: 36% 52% auto; */
  grid-template-columns: 36% 52% auto;

  align-items: center;
  font-weight: bolder;
  padding: 0.75em 1em;
  margin-top: 1em;
  border-radius: 5px;
}
.ticketItem {
  padding-left: 30px;
}

.ticketPrice {
  /* margin-left: 2em; */
}

.ticketTableHeaderV2 {
  background-color: var(--color-card-header-bg);
  display: grid;
  /* grid-template-columns: 46% 40% 25%; */
  /* grid-template-columns: 36% 52% auto; */
  /* grid-template-columns: 30% 25% 30% 15%; */
  /* grid-template-columns: 20% 20% 20% 30% 10%; */
  /* grid-template-columns: 15% 15% 15% 30% 15% 10%; */
  /* grid-template-columns: 14.5% 6% 9% 40% 21% 8%; */
  /* grid-template-columns: 10% 7% 7% 10% 15% 30% 10%; */
  /* grid-template-columns: 10% 5% 10% 10% 15% 15% 20% 15%; */
  /* grid-template-columns: 20% 10% 10% 20% 10% 20% 10%; */
  /* grid-template-columns: 10% 7% 5% 10% 15% 15% 30% 8%; */
  /* grid-template-columns: 10% 9% 5% 10% 15% 15% 30% 5%; */
  grid-template-columns: 15% 6% 6% 15% 10% 7.5% 31.5% 5% 5%;

  align-items: center;
  font-weight: bolder;
  padding: 0.75em 1em;
  margin-top: 1em;
  border-radius: 5px;
}
.ticketTableHeaderV2Sub {
  background-color: var(--color-card-header-bg);
  display: grid;
  grid-template-columns: 15% 6% 6% 13% 10% 15% 30% 5%;
  align-items: center;
  font-weight: bolder;
  padding: 0.75em 1em;
  margin-top: 1em;
  border-radius: 5px;
}
.ticketTableHeaderV2Major {
  background-color: var(--color-card-header-bg);
  display: grid;
  grid-template-columns: 14.5% 6% 9% 33% 15% 15% 8%;
  align-items: center;
  font-weight: bolder;
  padding: 0.75em 1em;
  margin-top: 1em;
  border-radius: 5px;
}

.creditTypesHeader {
  /* margin-left: 25px; */
}
.creditsRequiredHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.centerSelf {
  justify-self: center;
}

.ticketTableBody {
  padding: 0.75em 1em;
}

.ticketTableRow {
  margin: 1em 0;
}

.badgesContainer {
  margin: 12px 0 5px 5px;
  display: flex;
  align-items: center;
}

.badgesList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 70%;
  justify-content: flex-start;
  margin: 0 12px;
}

.speakerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.removeSpeaker {
  cursor: pointer;
  margin-left: 2px;
}
.addBadgesButton {
  margin: 0 100px -4px 0;
}
/* .ticketTableBody {
  width: 100%;
  min-width: 100%; 
  overflow-x: auto;
  overflow-y: hidden;
  display: block; 
  white-space: nowrap; 
  scrollbar-width: thin; 
  scrollbar-color: #ccc #f4f4f4; 
}

.ticketTableHeaderV2, .ticketTableRow {
  min-width: 1150px; 
  width: 1150px; 
} */

.ticketModal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.ticketDescription {
  display: flex;
  width: 100%;
  align-items: center;
}
.ticketInputItem {
  display: flex;
  width: 95%;
  align-items: center;
  margin: 6px;
}

.inputContainer {
  margin: 10px 0px 0px 15px;
  display: flex;
  align-items: center;
  width: 100%;
}

.titleInput {
  width: 85%;
}

.bottomInputContainer {
  margin: 20px 0px 0px 15px;
  display: flex;
  align-items: center;
}

.inputLabel {
  font-weight: bolder;
}

.inputLabelContainer {
  width: 155px;
}
