.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  border: 3px solid var(--color-border);
  border-radius: 6px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  z-index: 201;
  font-family: var(--font-family) !important;
  font-weight: lighter !important;
}

.modalContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  font-family: var(--font-family) !important;
}

.errorMessage {
  color: #ff0000;
  font-size: 16px;
  margin: 0 0 20px 0;
  font-family: var(--font-family) !important;
}

.closeButton {
  align-self: flex-end;
  padding: 5px 20px;
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 600;
  font-family: var(--font-family) !important;
}

.closeButton:hover {
  filter: brightness(1.2);
}
