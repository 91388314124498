.fontsize {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}

.ticketGroup {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.addBtn {
  cursor: pointer;
  margin-left: 2px;
  margin-top: 15px;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.description {
  display: flex;
  width: 100%;
  align-items: center;
}
.inputItem {
  display: flex;
  width: 90%;
  align-items: center;
  margin: 24px;
}

/*View Page Styling*/
.ticketGroupContainer {
  width: 100%;
  justify-content: center;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  font-size: 15px;
}

.v2Header {
  width: 100%;
  background-color: var(--color-bg-accent);
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: 30px;
  padding: 0.75em 1em;
  border-radius: 5px;
}

.viewContainer {
  margin: 10px 0px 0px 15px;
  display: block;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7em;
  flex-grow: 1;
  width: 100%;
}

.viewLabel {
  display: inline-block;
  font-size: 15px;
  font-weight: bolder;
  min-width: 170px;
}

.viewValue {
  font-size: 15px;
}

.viewIcon {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.body {
  width: 100%;
  padding: 1em;
}

.ticketTableHeaderV2 {
  background-color: var(--color-card-header-bg);
  display: grid;
  grid-template-columns: 15% 8% 7% 12% 10% 7.5% 21.5% 20%;

  align-items: center;
  font-weight: bolder;
  padding: 0.75em 1em;
  margin-top: 1em;
  border-radius: 5px;
}

.ticketTableBody {
  padding: 0.75em 1em;
}

.viewTicketItem {
  min-height: 100px;
  font-size: 15px;
  display: grid;
  grid-template-columns: 15% 8% 7% 12% 10% 7.5% 21.5% 20%;
  margin-bottom: 10px;
  align-items: center;
}

.creditTypesItem {
  display: flex;
  flex-direction: column;
}

.groupItem {
  display: flex;
  flex-direction: column;
}

.dateItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
