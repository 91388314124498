.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;
}

.modalContent {
  background-color: var(--color-card-bg);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--color-shadow);
  position: relative;
}

.alert {
  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);

  padding: 0.75rem 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1rem;
  position: relative;
}

.customAlert {
  padding: 10px;
  border-radius: 4px;
}

.alertError {
  background-color: var(--color-background-color-error);
  color: var(--color-text-error);
}

.closeBtn {
  width: auto !important;
  height: 32px !important;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 5px;
  cursor: pointer;
}

.closeBtn:hover {
  opacity: 0.5;
}

/* .alertCloseBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */
