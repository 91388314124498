.container {
  display: flex;
  flex-flow: column nowrap;
}

.header {
  margin-top: 35px;
  padding: 0 1em;
}

.tips {
  margin-top: 1em;
  margin-bottom: 1em;
}

.tips > header {
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--color-border);
}

.tips > section:not(:last-child) {
  margin-bottom: 1em;
}

.tipLabel {
  margin-bottom: 0.25em;
}

.tipText code {
  background-color: var(--color-noselection-light-bg);
}

@media (prefers-color-scheme: dark) {
  .tipText code {
    background-color: var(--color-noselection-dark-bg);
  }
}

.recents {
  padding: 1em;
}

.recents > header {
  margin-bottom: 0.5em;
}

.recentsList {
  list-style-position: inside;
}

.recentsList > li {
  height: 1.6em;
}
