.modal {
  position: absolute;
  right: 50%;
  top: 20%;
  transform: translate(50%, 0%);
  width: 650px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
  height: auto;
  padding-bottom: 30px;
}

.container {
  font-size: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
}

.label {
  font-weight: bold;
  font-size: 15px;
  min-width: 150px;
}

.inputItem > div {
  display: grid;
  grid-template-columns: 25% 65%;
  padding-bottom: 10px;
}

.input input {
  padding: 5px;
  padding-left: 5px;
  padding-left: 10px;
  height: 30px;
  font-size: 15px;

  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.divider {
  border-bottom: 0.5px solid var(--color-border);
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  float: right;
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-text) !important;
  border-radius: 8px;
  height: 32px;
  width: 75px;
  font-size: 15px;
}

.error {
  color: var(--color-text-red);
  white-space: nowrap;
  overflow: visible;
  font-size: 10px;
}

.timeError {
  color: var(--color-text-red);
  white-space: nowrap;
  overflow: visible;
  font-size: 10px;
  grid-column: 5 / span 1;
}

.inputError input {
  border-color: var(--color-text-red);
}

/* .tentative {
  width: 64%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox div {
  display: flex;
}

.checkbox input {
 
  border-radius: 2px;
  width: 18px;
  height: 18px;
  align-self: center;
} */

.dateRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7em;
  flex-grow: 1;
  width: 100%;
}

.datePicker input,
.timePicker input {
  font-size: 13.5px;
  font-weight: normal;
  height: 23px;
  width: 120px;

  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.datePicker input::placeholder,
.timePicker input::placeholder {
  color: var(--color-input-placeholder);
}

.datePicker div {
  border-radius: 5px !important;
  font-size: 11px;
}

.datePicker div svg {
  height: 16px !important;
  width: 16px !important;
  padding: 0 !important;
  font-size: 14px;
}

/* Time */

.timePicker {
  margin-left: 10px;
  margin-right: 20px;
}

.timePicker div {
  height: 22px !important;
}

.timePicker div svg {
  padding: 0 !important;
  font-size: 14px;
}

.timePicker button {
  font-size: 11.5px;
  font-weight: normal;
}

.timezone div {
  width: 60px;
  font-size: 12px;
  font-weight: normal;
}
