.card {
  display: flex;
  flex-flow: column nowrap;
  padding: 1em;
  background-color: var(--color-card-bg);
  border-radius: 12px;
  border: 3px solid var(--color-border);
  margin-bottom: 1em;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 1340px) {
  .card {
    min-width: 750px;
  }
}
