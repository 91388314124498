.container {
  width: 100%;
  position: relative;
}

.none {
  display: none;
}

.block {
  display: block;
  color: var(--color-text);
  width: 100%;
  padding: 0.5em 0 0.7em 0;
  background-color: var(--color-dropdown-bg);
  font-size: 1em;
  font-weight: 300;
  border: 1px solid var(--color-input-border);
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
}

.button_closed {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2em;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  font-weight: bold;
  border: 1px solid var(--color-button-default-border);
  border-radius: 7px;
  overflow: hidden;
  align-items: center;
  padding: 1em;
}

.button_open {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 1em;
  background-color: var(--color-bg);
  color: var(--color-input-text);
  font-weight: bold;
  border: 1px solid var(--color-input-border);
  border-radius: 7px;
  align-items: center;
  padding: 1em;
}

.block hr {
  background-color: var(--color-border);
  border: none;
  height: 1px;
  margin: 5px 10px;
}

.items {
  padding: 0.2em 0.75em;
  cursor: pointer;
  height: fit-content;
}

.items:hover {
  background-color: var(--color-dropdown-bg-hover);
}

.btnDisabled button:disabled,
button[disabled] {
  border: 1px solid var(--color-input-border);
  background-color: var(var(--color-input-bg));
  color: var(--color-text);
}

.dropdown {
  width: 100%;
  position: absolute;
  z-index: 100;
  background-color: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 7px;
}
