.header {
  font-size: 16px;
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.container {
  margin-bottom: 0.7em;
}

.container > div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.container span,
.label {
  min-width: 170px;
  font-size: 15px;
}

.container input {
  margin-top: 0.2em;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}

.visibleTo {
  font-size: 14px;
  width: 145px;
  margin-right: 15px;
}

.visibleTo span svg {
  float: right;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7em;
  flex-grow: 1;
  width: 100%;
}

@media (max-width: 1280px) {
  .container {
    font-size: 14px;
  }
}
