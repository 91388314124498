.modal {
  position: absolute;
  right: 50%;
  top: 30%;
  transform: translate(50%, 0%);
  width: 600px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
}

.button {
  float: right;

  background-color: var(--color-button-white-bg);
  border: 2px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px !important;
  font-weight: bold;

  margin-left: 22px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.refundBtn {
  background-color: var(--color-button-delete);
  border-color: var(--color-button-delete-border);
  color: var(--color-button-text);
  margin-right: 7px;
}

.refundBtn:hover {
  background-color: var(--color-button-delete-hover) !important;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.label{
  padding-right: 10px;
  margin-bottom: 50px;
}
.confirm{
  margin-top: 20px;
  font-weight: 500;
}
.success {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #d4edda;
  /* Light green background */
  color: #155724;
  /* Dark green text */
  text-align: center;
}

.danger {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  /* Light red background */
  color: #721c24;
  /* Dark red text */
  text-align: center;
}