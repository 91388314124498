.container {
  width: 100%;
  height: auto;
  min-width: 768px;
}

.modal {
  position: absolute;
  right: 50%;
  top: 2.5%;
  transform: translate(50%, 0%);
  width: 900px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  /* z-index: 201; */
  height: auto;
  max-height: 95vh;
  padding-bottom: 50px;
  overflow-y: auto;
}
.radioButtonListContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
}
.radioButtonList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 5px;
  border-radius: 8px;
  border: 2px solid var(--color-input-border);
  background-color: var(--color-input-bg);
  width: 100%;
}
.radioButtonList > * {
  flex: 1;
  justify-content: center;
  height: 20px;
}

.radioCol {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  justify-content: center;
  /* text-align: center; */
  align-items: center;
}
.label {
  font-weight: bold;
  font-size: 15px;
  text-align: right;
  padding-right: 74px;
}

.inputRow .radioButtonList {
  /* padding-left: 10px; */
}

.container button {
  height: 28px;
}

.inputRow {
  display: grid;
  align-items: center;
  margin-bottom: 15px;
}
.section,
.section .inputRow div {
  font-size: 15px;
}

.inputRow:nth-of-type(-n + 2) div,
.inputRow:nth-of-type(4) div {
  grid-template-columns: 17% 83%;
  white-space: nowrap;
}

.inputRow:nth-of-type(3) div {
  grid-template-columns: 17% 50%;
  white-space: nowrap;
}

.inputRow:nth-of-type(4) div,
.inputRow:nth-of-type(5) div {
  grid-template-columns: 17% 20%;
  white-space: nowrap;
}

.inputRow div span {
  width: 60%;
}

.section {
  margin-bottom: 15px;
}

.inputRow input .inputRow {
  display: flex;
  height: 28px;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-input-border);
  color: var(--color-input-text);
  border-radius: 8px;
  opacity: 0.6;
  padding-left: 10px;
}

.inputRow input::placeholder {
  color: var(--color-input-placeholder);
}

.inputRow input:focus {
  border: 3px solid var(--color-input-border-focus);
}

.section:nth-of-type(3) .inputRow,
.section:nth-of-type(5) .inputRow {
  display: flex;
}
.section:nth-of-type(3) .inputRow div:nth-of-type(1),
.section:nth-of-type(5) .inputRow:nth-of-type(1) div:nth-of-type(1) {
  grid-template-columns: 29.5% 70%;
  white-space: nowrap;
  width: 57.3%;
}
.section:nth-of-type(5) .inputRow:nth-of-type(2) div:nth-of-type(1) {
  grid-template-columns: 29.5% 69.6%;
  white-space: nowrap;
  width: 57.4%;
}
.section:nth-of-type(3) .inputRow div:nth-of-type(2) {
  padding-left: 22px;
  grid-template-columns: 45% 54%;
  white-space: nowrap;
  width: 28%;
}
.section:nth-of-type(5) .inputRow:nth-of-type(1) div:nth-of-type(2),
.section:nth-of-type(5) .inputRow:nth-of-type(2) div:nth-of-type(2) {
  padding-left: 22px;
  grid-template-columns: 37% 62%;
  white-space: nowrap;
  width: 28%;
}
.inputRow:nth-of-type(4) div,
.inputRow:nth-of-type(5) div,
.inputRow:nth-of-type(6) div,
.inputRow:nth-of-type(7) div,
.inputRow:nth-of-type(8) div,
.inputRow:nth-of-type(9) div,
.inputRow:nth-of-type(10) div {
  grid-template-columns: 17% 83%;
  white-space: nowrap;
}

.addButton {
  margin-top: 20px;
}

.saveBtn {
  font-size: 15px;
}

.saveBtn * {
  margin-top: 5px;
}

.save {
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 15px 20px;
}

.removeBtn {
  margin-left: 25px;
}

.remove {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  opacity: 1;
  /* background-color: var(--color-button-white-bg); */
}

.remove:hover {
  background: var(--color-button-white-hover) 0% 0% no-repeat padding-box;
}

.save:hover {
  background: var(--color-button-default-hover) 0% 0% no-repeat padding-box;
}

.errorsContainer .error:last-child {
  margin-bottom: 24px !important;
}

.error {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  padding: 6px 12px;
  margin: 5px auto;
  border: 1px solid transparent;
  border-radius: 1px;
  font-size: 16px;
  width: 80%;
  max-width: 90%;

  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);
}
