/* Extra big screen devices (1200px and above) */
/* @media only screen and (min-width: 1200px) {
  .modal {
    width: 900px;
  }
} */

.container {
  width: 100%;
  /* height: auto; */
}

.modal {
  position: absolute;
  right: 50%;
  top: 5%;
  transform: translate(50%, 5%);
  width: 1150px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
  height: auto;
  max-height: 95vh;
  padding-bottom: 30px;
}

.loader {
  position: absolute;
  right: 50%;
  top: 5%;
  transform: translate(50%, 5%);
  width: 1150px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
  height: auto;
  max-height: 95vh;
  padding-bottom: 30px;
}

.filterContainer {
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  /* border: 1px solid #afafaf;
  border-radius: 0.25em;
  padding: 16px; */
  border: 3px solid var(--color-border);
  border-radius: 12px;
  padding: 20px;
  width: 55%;
  font-weight: bold;
  letter-spacing: 0px;
  color: var(--color-text);
  display: flex;
  flex-direction: row;
  /* font-size: 14px; */
  font-size: 15px;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexCol .pinLevel {
  margin-right: 16px;
}

.title {
  margin-bottom: 6px;
}

.search {
  width: 55%;
  margin-right: 16px;
  display: flex;
  margin-top: 25px;
}

.search input {
  width: 100%;
  background-color: var(--color-dropdown-bg);
  border: 1px solid var(--color-input-border);
  color: var(--color-text);
}

.search input::placeholder {
  color: var(--color-input-placeholder);
}

.search input:focus {
  border: 1px solid var(--color-input-border-focus);
}

.search div input {
  width: 100%;
  height: 28px;
}

.pinLevel span {
  margin-top: -2px;
}

.pinLevel button {
  /* margin-top: 6px; */
  height: 28px;
  width: 110px;
}

.pinLevel button span {
  /* font-size: 14px; */
  font-size: 15px;

  align-items: center;
  display: flex;
}

.button {
  margin-top: 24px;
  width: 130px;
  height: 30px;
}

.button button {
  height: 28px;
  background: var(--color-button-accent) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border-color: var(--color-button-accent);
  align-items: center;
}

/* Speakers Card */
.speakersListOutput {
  background: var(--color-card-bg) 0% 0% no-repeat padding-box;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  padding: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--color-text);
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  /* font-size: 14px; */
  font-size: 15px;

  height: auto;
  max-height: 400px;
}

.iboList {
  display: block;
  width: 1060px;
  max-height: 275px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tableTitle {
  text-align: left;
  font-weight: bold;
  width: 20%;
  margin-right: 16px;
  margin-bottom: 10px;
  display: flex;
  font-size: 15px;
}

.hr {
  width: 100%;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-bottom: 10px;
}

/* Speaker list */
.tableContainer {
  width: 100%;
  margin-top: 13px;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  line-height: 35px;
}

.tableContainer thead {
  width: 100%;
}

.tableSorter {
  height: 35px;
}

.tableSorter button {
  padding-left: 0px;
}

.tableSorter button div {
  color: var(--color-text);
}

.tableHeader {
  background-color: var(--color-card-header-bg);
  color: var(--color-text);
  font-weight: bold;
}

.pageContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.addButton {
  float: right;
  font-size: 15px;
  margin-top: 5px;
}

.addButton button {
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-default);
  border-radius: 8px;
  opacity: 1;
  height: 32px;
  width: 75px;
}

.checkboxHeader {
  width: 2.5%;
}

.nameHeader {
  width: 25%;
}

.iboHeader {
  width: 10%;
}

.usernameHeader {
  width: 13.5%;
}

.emailHeader {
  width: 25%;
}

.lastScheduledHeader {
  width: 15%;
}

@media (max-width: 1540px) {
  .modal {
    top: -2%;
  }
}

@media (max-width: 1280px) {
  .speakersListOutput {
    max-height: 300px;
    overflow-x: scroll;
    overflow-y: scroll;
  }

  .modal {
    max-height: 95vh;
    padding-bottom: 30px;
  }
}
