.grid {
  display: grid;
  grid-template-columns: 30% 70%;
}

.label {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}

.input {
  border-radius: 8px;
  border: 2px solid var(--color-input-border);
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  padding: 5px;
  padding-left: 10px;
  font-size: 15px;
  height: 30px !important;
}

.input:focus {
  outline: none;
  border: 2px solid var(--color-input-border-focus);
}

.input::placeholder {
  color: var(--color-input-placeholder);
}
