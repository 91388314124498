.container {
  width: 100%;
  height: auto;
  color: var(--color-text);
  border: 3px solid var(--color-border);
  border-radius: 12px;
  background-color: var(--color-card-bg);
  padding: 18px;
  padding-left: 17px;
  padding-right: 17px;
  /* font-size: 1em; */
}

.cardHeader {
  font-size: 16px;
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 15px;
}

.text {
  white-space: pre-wrap;
  font-family: inherit;
}

.text input {
  outline: none !important;
  border: none !important;
}

@media (max-width: 1280px) {
  .textAreaLabel,
  .textArea {
    font-size: 14px;
  }
}
