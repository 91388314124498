.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  width: 650px;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
  font-size: 16px;
  height: auto;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 19% 35% 16% auto;
  width: 100%;
  font-size: 15px;
}

.leftSide {
  font-weight: bold;

  margin-bottom: 10px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .button {
  float: right;

  background-color: #2a3847;
  color: #fbfcfd;
  border: none;
  border-radius: 8px;

  height: 25px;
  width: 74px;

  font-size: 12px;
  font-weight: bold;

  margin-left: 22px;
  margin-top: 20px;
  margin-bottom: 2px;
}

.button:hover {
  cursor: pointer;
}
.button:active {
  filter: invert(10%);
} */

/* .searchButton {
  float: right;

  background-color: #9ab5bb;
  color: #fbfcfd;
  border-radius: 8px;
  border: none;

  height: 28px;
  width: 94px;

  font-size: 13px;
  font-weight: bold;
} */

/* .box1 {
  margin-top: 20px;
  margin-bottom: 15px;
  border: 1px solid #afafaf;
  border-radius: 5px;

  padding: 18px;
}

.box2 {
  margin-top: 10px;
  border: 1px solid #afafaf;
  border-radius: 5px;

  padding: 18px;
} */

/* .gray {
  color: #afafaf;
} */

.bold {
  font-weight: bold;
  font-size: 15px;
}

.item,
.item2 {
  margin-bottom: 13px;
  max-width: 200px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.selectTitle {
  margin-top: 15px;
  margin-bottom: 20px;
}

.submitButton {
  display: flex;
  font-size: 15px;
  margin-top: 10px;
}
.submitButtonEnd {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 15px;
  margin-top: 10px;
}

.submitButton button {
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-default);
  border-radius: 8px;
  opacity: 1;
  height: 32px;
  margin-bottom: 10px;
}

.radioButtonListContainer {
  color: var(--color-text);
}

.radioButtonListContainer > div > span > span {
  right: 10px;
  top: -8px;

  /* background-color: var(--color-radio-bg); */
}
