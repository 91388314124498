.header {
  color: var(--color-text);
  font-size: 1em;
  font-weight: bold;
  text-align: left;
}

.none {
  display: none;
}

.back {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text);
  font-size: 0.66em;
  text-align: left;
  cursor: pointer;
  transform: translateX(-1.7px);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.children {
  display: flex;
  flex-direction: row;
}
