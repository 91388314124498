.row {
  display: flex;
  line-height: 30px;
  cursor: pointer;
}

.row.selected .rowHourNumber {
  font-weight: bold;
}

.row.selected .rowHour {
  background-color: var(--color-datepicker-selected-bg);
  color: var(--color-datepicker-selected-text);
}

.row:hover {
  background-color: var(--color-datepicker-selected-bg);
  color: var(--color-datepicker-selected-text);
}

.row:hover .rowMinute {
  color: var(--color-datepicker-selected-text);
}

.row:hover .rowHourNumber {
  font-weight: bold;
}

.row:hover .rowMinute {
  opacity: 1;
}

.rowHour {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  font-weight: normal;
  padding-right: 0.5em;
}

.rowHour:hover .rowHourLabel {
  font-weight: bold;
}

.rowHourNumber {
  margin-right: 0.3em;
  width: 1.6em;
  text-align: right;
}

.rowHourLabel {
  width: 1.5em;
}

.rowHourLabel.selected {
  font-weight: bold;
}

.rowMinute {
  flex: 1 1 100%;
  text-align: center;
  color: var(--color-text-gray);
}

.rowMinute.selected {
  font-weight: bold;
  opacity: 1;
  color: var(--color-datepicker-text);
}

.rowMinute:hover {
  font-weight: bold;
}
