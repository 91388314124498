.layout {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;

  overflow: hidden;
  max-width: 100vw;
  min-width: 1150px;
}

.main {
  flex: 1 1 100%;
}
body,
html {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  /* Enables horizontal scrolling */
}
.notFound {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.notFound > h1 {
  margin-bottom: 0.25em;
}

.notFound > p {
  font-size: 1.1em;
}

.externalLinks {
  border-top: 1px solid var(--color-nav-main-border);
  margin-top: 0.5em;
  padding-top: 0.5em;
}
