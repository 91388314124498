.container {
  height: 100%;
  position: relative;
  z-index: 3;
}
.title {
  font-weight: bold;
}

.image {
  height: 35px;
  width: auto;
  margin-left: 22px;
  margin-bottom: -2px;
}

.inline {
  display: inline-block;
}

.date {
  position: absolute;
  line-height: 35px;
  margin-left: 10px;
}

.editButton {
  margin-top: 13px;
  width: 100%;
  min-width: 40px;
  height: 100%;
  min-height: 20px;

  background-color: var(--color-input-bg);
  border-radius: 8px;
  border: 1px solid var(--color-input-border);
  font-weight: lighter;
  color: var(--color-text);
  padding-top: 2px;
}

.editButton:hover {
  cursor: pointer;
  /* background-color: rgb(250, 250, 250); */
}

.cancelButton {
  margin-right: 7px;
}

.editButton:active {
  cursor: pointer;
  /* background-color: rgb(209, 209, 209); */
}

.selectDropdown {
  display: inline-block;
  text-align: left;
  background-color: var(--color-dropdown-bg);
  border: 1px solid var(--color-input-border);
  color: var(--color-text);
  border-radius: 0.5em;
  padding: 0.3em 0.1em 0.4em 0.8em;
  height: 2em;
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  color: var(--color-text);
  cursor: pointer;
  user-select: none;
}

.selectDropdown:enabled .selectChevron {
  position: absolute;
  width: fit-content;
  right: 0%;
  transform: translateY(-0.1em);
  padding-right: 5px;
}

.selectDropdown:disabled .selectChevron {
  position: absolute;
  right: 0%;
  transform: translateY(-0.1em);
  padding-right: 5px;
}

.nonClickable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
