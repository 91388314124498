.title {
  font-size: 16px;
  font-weight: bold;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7em;
}

.venueRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7em;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.leftCol {
  width: 64%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rightCol {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.warningText {
  color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.updateBtn {
  margin-left: 12px;
  height: 25px;
  width: 55px;
  font-size: 13px !important;
}

.row:last-child {
  margin-top: -0.5em;
}

.label {
  font-weight: bold;
  font-size: 15px;
  min-width: 170px;
}

.filterDropdown {
  min-width: 181px;
  margin-right: 0.5em;
  font-size: 14px;
}

.filterDropdown div,
.filterDropdown div div button {
  font-size: 14px;
}

.venueDropdown {
  min-width: 410px;
  /* width: 39%; */
  /* font-size: 10px; */
  font-size: 14px;
  margin-right: 48px;
}

.infoCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 48px;
}

.infoCard > div > div {
  left: 75%;
  grid-template-columns: 30% 70%;
}

.infoCard > div {
  width: 410px;
}

.checkbox div {
  display: flex;
}

.checkbox input {
  background-color: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-border);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  align-self: center;
}

.checkbox input:after {
  border: 1px solid var(--color-checkbox-border-active);
}

.checkbox:checked:after {
  background: var(--color-checkbox-border-active);
}

.text {
  font-size: 14px;
  margin-top: 5px;
}

.button {
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.disabledAdd {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  font-size: 14px;
  margin-top: 5px;
}

.loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;

  /* height: 90vh; */
}
