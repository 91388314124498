.container {
  --size: 22px;
  --icon-size: 14px;

  width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  appearance: none;
  outline: none;
  background-color: var(--color-checkbox-bg);
  border: 3px solid var(--color-checkbox-border);
  border-radius: 6px;

  width: var(--size);
  height: var(--size);
  position: absolute;
  cursor: pointer;
}

.checkbox:checked {
  background-color: var(--color-checkbox-bg-active);
  border: 3px solid var(--color-checkbox-border-active);
}

.checkbox:checked + .icon {
  visibility: visible;
}

.icon {
  visibility: hidden;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.icon > svg {
  width: var(--icon-size);
  height: var(--icon-size);
  stroke-width: 4;
  stroke: var(--color-checkbox-icon);
}

.checkbox:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox:disabled + .icon {
  opacity: 0.5;
}
