.redContainer,
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background-color: var(--color-card-bgr);
  border-radius: 7px;
  border: 1px solid var(--color-border);
  font-size: 15px;
  min-width: 400px;
  padding: 15px 20px 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.redContainer {
  border-color: var(--color-text-red);
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 25% auto;
}
.grid2 {
  width: 100%;
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-rows: auto 1fr; /* First row for the link, second for the rest */
}

.link {
  grid-column: 2;
  justify-self: flex-end;
  text-decoration: underline;
  color: var(--color-t);
  margin-bottom: 8px;
}

.left {
  font-weight: bold;
}

.item {
  margin-bottom: 15px;
}

.link:hover {
  cursor: pointer;
}

.address span {
  display: block;
}

.modal {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.inputRow {
  width: 100%;
  padding: 6px 12px;
}
.saveBtn {
  margin: 12px;
}

@media (max-width: 1100px) {
  .redContainer,
  .container {
    font-size: 14px;
    min-width: 515px;
    max-width: 575px;
    padding: 10px;
  }

  .grid {
    display: grid;
    grid-template-columns: 25% auto;
  }
  .grid2 {
    display: grid;
    grid-template-columns: 25% auto;
  }
}
