.title {
  font-size: 16px;
  font-weight: bold;
}

.row {
  display: flex;
  flex-direction: row;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.bookingStatus {
  min-width: 150px;
  height: 26px;
}
.bookingText {
  margin-left: 15px;
}

.visibleToCol span:nth-child(2) {
  /* font-size: 15.5px !important; */
  /* font-size: 14px; */
}

.label {
  font-weight: bold;
  font-size: 15px;
}

.col,
.visibleToCol,
.tentative {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 0.7em;
}

.col > .label,
.visibleToCol > .label,
.tentative > .label {
  min-width: 170px;
  max-width: 170px;
}

.colInput {
  min-width: 200px !important;
  max-width: 575px !important;
  font-size: 15px !important;
  align-items: center !important;
}

.colInput > input {
  padding-top: 5px !important;
}

.inputItem,
.disabled {
  min-width: 230px !important;
  max-width: 230px !important;
  /* margin-bottom: 0.7em; */
  height: 28px !important;
  margin: 5px 0;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.colInput input {
  font-size: 14px !important;
}

.tentative {
  width: 64%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox div {
  display: flex;
  margin-left: 2px;
}

.checkbox input {
  background-color: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-border);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  align-self: center;
  margin-left: 2px;
}

.checkbox input:after {
  border: 1px solid var(--color-checkbox-border-active);
}

.checkbox:checked:after {
  background: var(--color-checkbox-border-active);
}

.viewLabel {
  font-weight: bold;
  font-size: 15px;
}

.textInput {
  font-size: 15px;
  /* margin-left: 10px; */
}

.textValue {
  font-size: 15px;
  margin-left: 15px;
}

.colValue {
  margin-bottom: 0.7em;
}

/* .colValue > .label {
  min-width: 200px;
  max-width: 170px;
} */

.loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 200px;
  /* align-items: center; */
  /* height: 90vh; */
}

/* @media (max-width: 1280px) {
  .label,
  .colInput > .label {
    font-size: 14px !important;
  }
} */

/* @media (max-width: 1099.98px) {
  .col > .label,
  .visibleToCol > .label,
  .tentative > .label {
    min-width: 160px;
  }

  .label {
    font-size: 14px;
  }

  .timePicker {
    margin-left: 5px;
    margin-right: 15px;
  }
} */

.checkbox input[type="checkbox"] {
  transition: opacity 0.3s ease;
}
