.title {
  font-weight: bold;
  font-size: 16px;
}

.headerContainer {
  display: flex;
}

.container {
  border-width: 1px;
}
.selectContainer {
  width: 500px;
}

/* .reactSelect > * > * {
  background-color: red;
  overflow: hidden;
}
.reactSelect > * > * > * > * {
  background-color: yellow;
  border: 1px solid yellow;
  display: flex;
} */

.icon {
  /* position: absolute; */
  /* top: 0;
  right: 0;
  bottom: 0; */
  /* width: 36px;
  display: flex;
  align-items: center; */
  /* justify-content: center; */
  /* pointer-events: none;
  margin-left: auto; */
}
.errorMessage {
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 12px;
  color: var(--color-surface-orange);
}

.form-container,
.form-container .input-container > div,
.event-description > div {
  display: flex;
  /* font-size: 14px; */
  font-size: 15px;
}

.form-container {
  margin-top: 0.75em;
  flex-direction: column;
  row-gap: 0.75em;
}

.input-container span,
.event-description label {
  min-width: 10em;
}

.input-container span:not(.no-margin),
.event-description label {
  margin-right: 1.3em;
}

.input-container input,
.event-description textarea {
  border-radius: 0.5em;
  flex-grow: 1;
  font-weight: 100;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.double {
  display: flex;
  justify-content: space-between;
  gap: 2em;
}

.double > div:first-child {
  flex-grow: 1;
}

.double > div:nth-child(2) span {
  width: 7em;
}

.flex,
.button-container > div {
  display: flex;
  align-items: center;
}

.button-container {
  cursor: pointer;
}

.button-container > div > span {
  margin-left: 2.9em;
}

.event-description > div {
  flex-direction: row;
}

.event-description textarea {
  width: auto;
  height: 5.5em;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-input-border);
  color: var(--color-input-text);
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
}

.event-description textarea::placeholder,
.inputItem div input::placeholder {
  color: var(--color-input-placeholder);
}

.event-description textarea:focus,
.inputItem div input:focus,
.date-picker input:focus {
  border: 2px solid var(--color-input-border-focus);
}

.inputItem div input {
  /* height: 28px; */
  border-radius: 8px;
  font-size: 14px;
  font-weight: normal;
  background: var(--color-input-bg);
  color: var(--color-input-text);
}

/* Start Date */

.input-container .picker-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.radioButtonList {
  color: var(--color-text);
  position: relative;
  border-radius: 8px;
  border: 2px solid var(--color-input-border);
  background-color: var(--color-input-bg);
  padding: 5px;
  padding-left: 10px;
  padding-top: 15px;
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.radioButtonList > * {
  margin-right: 80px;
}

.stateCol,
.radioCol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stateCol > .label,
.radioCol > .label {
  max-width: 170px;
  min-width: 170px;
  font-weight: bold;
  font-size: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7em;
}

.select {
  font-size: 14px;
}

.select div {
  width: 17em;
}

.onSiteOrVirtualIndicatorOff {
  background-color: var(--color-bg);
  color: var(--color-button-disabled);
}

.disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  width: 14em;
}

.text {
  font-size: 15px;
  font-weight: normal;
}

.label {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}

.statelabel {
  font-weight: bold;
}

.stateValue {
  font-size: 15px;
}

.warningBorder {
  border: 1.8px solid var(--color-surface-orange);
}

.validBorder {
  border: 1.8px solid var(--color-surface-green);
}
