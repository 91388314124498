.container {
  font-size: 14px;
}

.rulesetTitle {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--color-border);
  padding-bottom: 0.6em;
  margin-bottom: 0.6em;
}

.ruleset {
  font-weight: bold;
}

.date {
  font-weight: lighter;
}

.rulesetRow {
  display: flex;
}

.rulesetRowTitle {
  width: 25%;
  font-weight: bold;
}

.rulesetRowDesc {
  width: 75%;
  margin-bottom: 1em;
  font-weight: bold;
  display: flex;
}

.createBtn {
  padding-left: 5px;
  text-decoration: underline;
  color: var(--color-link);
  cursor: pointer;
}

.createBtn:hover {
  color: var(--color-link-hover);
}

@media (max-width: 1400px) {
  .container {
    font-size: 12px;
  }

  .rulesetRowTitle {
    width: 30%;
  }

  .rulesetRowDesc {
    width: 70%;
  }
}

.rulesetRowDesc a {
  color: var(--color-link);
  text-decoration: underline !important;
}
