.container {
  width: 100%;
  height: auto;
}

.header {
  font-weight: bold;
}

.publishingInfoCardTitle {
  font-weight: bold;

  font-size: 15px;
}

.hr {
  width: 100%;
  background-color: var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
  border: 0.5px solid var(--color-border);
}

.publishingDiv {
  display: grid;
  grid-template-columns: 45% auto;
}

.item {
  margin-bottom: 16px;
}
