.container {
  width: 100%;
  height: auto;
  color: var(--color-text);
  /* background-color: white; */
  /* color: black; */

  /* border: 2px solid #afafaf;
  border-radius: 5px;

  padding: 18px;
  padding-left: 17px;
  padding-right: 17px; */
}

.cardHeader {
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.button {
  float: right;
  background: var(--color-button-default);
  border: none;
  color: var(--color-button-text);
  border-radius: 6px;
  height: 26px;
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
  /* font-size: 11px; */
  font-weight: bold;

  transform: translateY(-6px);
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.button:active {
  /* filter: invert(10%); */
  background-color: var(--color-button-white-bg);
}

.hostCard {
  margin-top: 12px;
}
