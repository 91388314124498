.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);

  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
}

.bold {
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 130px auto;
  margin-top: 24px;
  margin-bottom: 14px;
  width: 600px;
}

.leftSide {
  font-weight: bold;
  margin-bottom: 10px;
}

.input {
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  border: 3px solid var(--color-input-border);
  border-radius: 8px;

  padding: 5px;
  padding-left: 10px;
  font-size: 15px;
}

.input:focus {
  outline: none;
  border: 3px solid var(--color-input-border-focus);
}

.input::placeholder {
  color: var(--color-input-placeholder);
}

.ruleset {
  margin-bottom: 14px;
}

.button {
  float: right;
  background-color: var(--color-button-default);
  color: var(--color-button-text) !important;
  border: 1px solid var(--color-button-default);
  border-radius: 8px;

  height: 32px;
  width: 75px;

  font-size: 15px;
  font-weight: bold;

  margin-left: 22px;
  margin-top: 18px;
  margin-right: 7px;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-default-hover);
}
/* .button:active {
  filter: invert(10%);
} */

.errorContainer {
  margin-left: 135px;
  margin-bottom: 20px;
}

.errorOrgMsg {
  height: 10px;
  color: var(--color-text-red);
  font-size: 13px;
  display: flex;
  /* margin: -2px 0px 15px 150px; */
  width: 100%;
  margin-bottom: 10px;
}
