.title {
  font-weight: bold;
  font-size: 15px;
}

.security label {
  color: var(--color-text);
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.security textarea {
  width: 100%;
  height: 5.5em;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-input-border);
  color: var(--color-input-text);
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;

  font-weight: normal;
}

.security textarea::placeholder {
  color: var(--color-input-placeholder);
}

.security > div {
  margin-bottom: 0.7em;
  font-size: 15px;
}

.security textarea:focus {
  outline: none;
  border: 2px solid var(--color-input-border-focus);
}

@media (max-width: 1280px) {
  .security > div {
    font-size: 14px;
  }
}
