.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 25px;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  border-radius: 7px;
  color: var(--color-button-white-text);
  margin-left: auto;
  transform: translateY(-2px);
  font-size: 13px;
  font-weight: 600;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.button:active {
  background-color: var(--color-button-active);
}

.importedBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 25px;
  color: var(--color-button-white-text);
  margin-left: auto;
  transform: translateY(-2px);
  font-size: 13px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.importedBtn:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.floatRight {
  margin-left: auto;
}
.button:disabled {
  border: none;
}
.center {
  margin: auto;
}

@media (max-width: 1540px) {
  .container {
    height: 450px;
    max-width: 1200px;
  }
}
.flexContainer {
  color: var(--color-text);
  padding-left: 1%;
  line-height: 35px;
  font-weight: 100 !important;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rulesetFlexItem,
.nameFlexItem,
.cityFlexItem,
.stateFlexItem,
.editFlexItem {
  display: flex;
  align-items: center;
}

.nameFlexItem {
  /* flex: 1; */
  width: 35%;

  margin-right: 20px;
  /* Gives more room to the name */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.locationFlexItem {
  width: 34%;
  /* Adjust based on your preference */
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-right: 20px;
}

.startsOnFlexItem {
  padding-right: 10px;
}

.importFlexItem {
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 10px;
}

.loader {
  margin-left: 60px;
}
