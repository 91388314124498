.container {
  border: 1px solid var(--color-input-border);
  border-radius: 8px;
  background-color: var(--color-input-bg);
  text-align: center;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 2;
  color: var(--color-text);
  padding: 15px;
  padding-bottom: 0px;
}

.arrow {
  height: 20px;
  width: 20px;
  border-radius: 6px;
  padding: 6px;

  margin: 0 3px;
}

.arrow:hover {
  cursor: pointer;
}

.rightArrow {
  float: right;
  transform: rotate(-90deg);
  box-shadow: -1px 0px 5px var(--color-text);
}

.leftArrow {
  float: left;
  transform: rotate(90deg);
  box-shadow: 1px 0px 5px var(--color-text);
}

.monthYear {
  font-weight: bolder;
  margin-bottom: 6px;
  line-height: 20px;
}

.monthYear2 {
  font-size: 12px;
}

.grid {
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px 40px 40px;
  width: auto;
  height: 182px;
  margin: auto;
}

.grid2 {
  display: grid;
  grid-template-columns: 20px 20px 20px 20px 20px 20px 20px;
  width: auto;
  height: 142px;
  margin: auto;
  font-size: 10px;
}

.gridItem {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 6px;
  margin: auto;
  user-select: none;
}

/* .gridItem2 {

} */

.days:hover {
  cursor: pointer;
  /* background-color: rgb(236, 236, 236); */
  background-color: var(--color-button-white-hover);
  color: var(--color-button-white-text) !important;
}

.weekDays {
  color: #bfc2c4;
}

.grayedOut {
  color: #b0b2b7;
}

.grayBg {
  background-color: rgb(236, 236, 236);
}

.highlighted {
  border: 1px solid var(--color-button-review-border);
  color: var(--color-button-review);
  background-color: var(--color-button-white-bg);
  line-height: 20px;
  z-index: 1;
  width: 40px;
}

.highlighted2 {
  border: 1px solid var(--color-button-review-border);
  color: var(--color-button-review);
  background-color: var(--color-button-white-bg);
  z-index: 1;
  width: 20px;
}

.rangeHighlight2 {
  background-color: #c8e4ff;
  border-radius: 0px;
  position: absolute;
  transform: translate(-5px, 5px);
  height: 12px;
  width: 25px;
  z-index: -100;
}

.rangeHighlight {
  background-color: #c8e4ff;
  border-radius: 0px;
  position: absolute;
  transform: translate(-5px, 5px);
  height: 12px;
  width: 40px;
  z-index: -100;
}

.disabled {
  color: var(--color-button-disabled);
  background-color: none;
}

.disabled:hover {
  cursor: default;
  background: none !important;
}

.button {
  width: 50px;
  height: 20px;
  border-radius: 5px;
  background-color: var(--color-button-white-bg);
  border: none;
  margin-top: 2px;
  margin-bottom: 8px;
  float: right;

  color: var(--color-button-review);
}

.button:hover {
  color: var(--color-button-review-hover);
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .arrow {
    filter: invert(1);
  }
}
