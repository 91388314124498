.container {
  font-size: 15px;
}

.fontSize {
  letter-spacing: 0px;
  color: var(--color-text);
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.entry {
  display: grid;
  grid-template-columns: 10% 85% auto;
  align-items: center;
  font-size: 15px;
  padding: 10px;
}

.entryTitle {
  font-weight: bold;
  font-size: 15px;
}

.entryParamsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.entryRemoveButtonContainer {
  align-items: center;
}

.removeButton {
  background-color: #ffcccc;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.removeButton:hover {
  background-color: #ff9999;
}
/* Render Params */
.paramContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
}

.paramItem {
  display: grid;
  grid-template-columns: 10% auto 90%;
  align-items: center;
  gap: 10px;
}
.paramItemDescription {
  width: 90%;
  display: grid;
  grid-template-columns: 13% auto 90%;
  align-items: center;
}

.type {
  font-weight: bold;
  text-align: flex-end;
}

.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.fieldInput {
  width: 90%;
  border-radius: 8px;
  border: 2px solid var(--color-input-border);
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  padding: 5px;
  padding-left: 10px;
  font-size: 15px;
  height: 30px !important;
}
.fieldInput:focus {
  outline: none;
  border: 2px solid var(--color-input-border-focus);
}
.inpfieldInputut::placeholder {
  color: var(--color-input-placeholder);
}
.fieldDescription {
  width: 100%;
  display: flex;
  align-items: center;
}
.fieldDescription > div {
  width: 100%;
}
.fieldDescription > div > textArea {
  height: 100px;
  border-width: 2px !important;
}
.fieldDescription > div > textArea:focus {
  border-width: 2px !important;
}

.iconContainer {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 24px;
}

.secondaryInputItem {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 13.5% 66.5% 5% 5%;
  align-items: center;
  font-size: 15px;
}

.secondaryInputItem > div {
  grid-template-columns: 15% 80%;
  font-size: 15px;
}

.removeButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  width: 5em;
  margin-left: -10px;
}

.removeButton:hover {
  background-color: var(--color-button-white-hover);
}
