.container {
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.headerContainer {
  padding: 1.2rem 0rem;
  position: sticky;
  top: -3%;
  width: 100%;
  z-index: 200;
  background-color: var(--color-bg);
  color: var(--color-text);
}

.backButton {
  width: 100%;
  display: flex;
  font-size: 12px;
}

.backButton a {
  color: var(--color-text) !important;
}

.pageTitle {
  text-align: left;
  font-weight: bold;
  font-size: 1.2em;
  color: var(--color-text) !important;
}

.venueControls {
  width: 100%;
  margin-top: 32px;
  color: var(--color-text);
  display: grid;
  grid-template-columns: 78% auto;
}

.venueControls div div div button {
  color: var(--color-text);
}

.venueControls div div div input {
  color: var(--color-text);
}

.createNewCard div {
  padding-top: 7px;
  padding-bottom: 13px;

  background-color: var(--color-bg);
  color: var(--color-text);
}

.venueList {
  height: 100%;
  margin-top: 0.5em;
}

/* .loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
} */

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.loaderText {
  display: flex;
  font-weight: bold;
  color: var(--color-text-accent);
}

@media (min-width: 1350px) and (max-width: 1380px) {
  .venueControls {
    grid-template-columns: 82.5% auto !important;
  }
}

@media (min-width: 1280px) and (max-width: 1349px) {
  .venueControls {
    grid-template-columns: 80% auto !important;
  }
}

@media (max-width: 1279px) {
  .venueControls {
    grid-template-columns: 90% auto !important;
    gap: 10px;
  }
}
