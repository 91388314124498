.searchBar {
    
    border: 1px solid var(--outline);
    background: #ebebeb;
    border: 1px solid #afafaf;
    border-radius: 0.5em;
    padding: 0.3em 0.1em 0.4em 0.8em;

    color: black;
    width: 100%;
    height: 100%;
    padding: .3em .1em .4em .8em;

    
    font-size: inherit;
    font-weight: 100 !important;
}

.searchBar::placeholder{
    color: #575656;
    font-weight: 100 !important;
}
.searchBar:focus{
    outline: none;
}
