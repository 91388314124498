.title {
  font-size: 16px;
  font-weight: bold;
}

.formBody {
  /* display: grid;
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
  grid-auto-rows: minmax(auto, 30px); */
  align-items: center;
}

.section {
  margin-bottom: 30px;
}

.row,
.dateRow {
  display: flex;
  flex-direction: row;

  margin-bottom: 0.7em;
  flex-grow: 1;
  width: 100%;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.leftCol {
  /* width: 64%; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  font-weight: bold;
  font-size: 15px;
  min-width: 170px;
}

.text,
.speakerDisplay {
  font-size: 15px;
}

.visibleTo {
  font-size: 14px;
  width: 145px;
  margin-right: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.button button {
  background-color: var(--color-button-default);
  border: 1px solid var(--dark-blue);
  border-radius: 5px;
  /* border: 1px solid #2a3847; */
  color: var(--color-button-text) !important;
  height: 32px;
  margin-top: 1px;
  font-size: 14px !important;
}

.button:hover {
  cursor: pointer;
}

.session > div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.session {
  margin-bottom: 0.7em;
}

.session span,
.description label {
  min-width: 170px;
  font-size: 15px;
}

.session input {
  margin-top: 0.2em;
  font-size: 14px;
  width: 100%;
}

.description textarea {
  width: 100%;
  height: 5.5em;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-input-border);
  color: var(--color-input-text);
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
}

.description textarea:focus {
  outline: none;
  border: 2px solid var(--color-input-border-focus);
}

.description > div {
  display: flex !important;
  flex-direction: row;
  margin-bottom: 0.7em;
}

.ticketGroup {
  background-color: var(--color-button-accent);
  color: var(--color-button-text);
  padding: 0.2em 0.5em;
  border-radius: 0.5em;
  margin-right: 1em;
  font-size: 14px;
}

.ticketGroupContainer {
  /* grid-column: 2 / span 4; */
  display: flex;
}

.speakerDisplay {
  grid-column-start: 2;
  padding-right: 0.5em;
}

.addSpeakerButton {
  font-size: 15px;
  margin-bottom: -8px;
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rooms {
  font-size: 14px;
  width: 50%;
}

.capacity {
  font-weight: bold;
  font-size: 15px;
  width: 50%;
}

/* Start Date */
.finalCol,
.dateCol {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.endDate {
  font-weight: bold;
  justify-self: center;
}

.datePicker input,
.timePicker input {
  font-size: 13.5px;
  font-weight: normal;
  height: 23px;
  width: 120px;

  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.description textarea::placeholder,
.datePicker input::placeholder,
.timePicker input::placeholder {
  color: var(--color-input-placeholder);
}

.datePicker div {
  border-radius: 5px !important;
  font-size: 11px;
}

.datePicker div svg {
  height: 16px !important;
  width: 16px !important;
  padding: 0 !important;
  font-size: 14px;
}

/* Time */

.timePicker {
  margin-left: 10px;
  margin-right: 20px;
}

.timePicker div {
  height: 22px !important;
}

.timePicker div svg {
  /* padding: 0 !important; */
  font-size: 14px;
}

.timePicker input {
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.timePicker button {
  font-size: 11.5px;
  font-weight: normal;
}

.timezone div {
  width: 60px;
  font-size: 12px;
  font-weight: normal;
}

.row > .selectRemove {
  margin-left: auto;
}

.removeButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid var(--color-button-default);
  color: var(--color-button-white-text);
  height: 24px;
  justify-content: flex-end;
}

.removeButton:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

@media (max-width: 1680px) {
  .dateRow {
    flex-direction: column;
  }

  .dateCol {
    width: 100%;
    margin-bottom: 0.5em;
  }
}

@media (min-width: 1280.98px) and (max-width: 1350px) {
  .capacity {
    margin-left: 3em;
  }
}

@media (min-width: 1191px) and (max-width: 1280px) {
  .capacity {
    margin-left: 3em;
  }

  .datePicker input,
  .timePicker input {
    font-size: 13px;
    width: 120px;
  }

  /* .timePicker {
    margin-left: -5px;
    margin-right: -10px;
  } */

  .capacity,
  .addSpeakerButton,
  .text,
  .speakerDisplay,
  .session span,
  .description label {
    font-size: 14px;
  }

  .session {
    margin-bottom: 0.7em;
  }
}

@media (max-width: 1190px) {
  .rooms {
    min-width: 120px;
  }

  .capacity {
    margin-left: 2em;
  }

  .datePicker input,
  .timePicker input {
    font-size: 13px;
    width: 120px;
  }

  .timePicker {
    margin-left: 5px;
    margin-right: 15px;
  }

  .label {
    font-size: 14px;
  }

  .capacity,
  .addSpeakerButton,
  .text,
  .speakerDisplay,
  .session span,
  .description label {
    font-size: 14px;
  }

  .session {
    margin-bottom: 0.7em;
  }
}
