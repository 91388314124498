.cardHeader {
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.container {
  min-height: 100px;
  width: 100%;
  font-size: 15px;
}

.row {
  display: grid;
  grid-template-columns: 13% 8% 5% 40%;
  margin-bottom: 0.7em;
  flex-grow: 1;
  width: 100%;
}

.label {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bolder;
}

.params {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.param1 {
  margin-top: 10px;
  margin-bottom: 25px;
}

.icon {
  height: 25px;
  width: 25px;
}
