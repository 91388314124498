@keyframes field-updated {
  0% {
    box-shadow: 0 0 10px rgba(0, 135, 212, 0.5);
  }

  100% {
    box-shadow: 0 0 10px rgba(0, 135, 212, 0);
  }
}

.container {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  position: relative;
}

.inputGroup {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.inputGroup.invalid {
  border: 1px solid red;
  box-shadow: 0 0 4px rgba(255, 20, 20, 0.5);
}

.inputGroup.invalid .dateInput {
  outline: none !important;
  box-shadow: none !important;
}

.inputGroup.updated {
  animation: field-updated 1s forwards;
}

.toggleButton {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-f);
  color: #fff;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 34px;
  cursor: pointer;

  border: none;
  outline: none;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
