.infoItemLabel {
  font-weight: bold;
}

.infoItemDescription {
  margin-bottom: 16px;
}

.infoItem {
  display: grid;
  grid-template-columns: 190px auto;
}
