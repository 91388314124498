.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
}

.picker:not(:last-child) {
  margin-bottom: 0.5em;
}
