.modal {
  position: absolute;
  right: 50%;
  top: 5%;
  transform: translate(50%, 0%);
  width: 980px;
  max-width: 90%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  z-index: 201;
  max-height: 86vh;
  overflow-y: scroll;
  padding: 20px;
}

/* .modal.undoChangesModal {
  z-index: 202;
} */

.savedValueList {
  display: flex;
  flex-direction: column;
}

.modal::-webkit-scrollbar {
  width: 8px;
  height: 20px;
  overflow: hidden;
}
.modal::-webkit-scrollbar-track {
  background-color: var(--color-card-bg);
}
.modal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

.header {
  font-weight: bold;
  display: grid;
  grid-template-columns: 27% 30% 34%;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.itemContainer {
  padding-bottom: 15px;
}

.item {
  display: grid;
  grid-template-columns: 27% 30% 34% auto;
  line-height: 25px;
  margin-top: 11px;
  margin-bottom: 5px;

  /* border-bottom: 0.5px solid var(--color-border); */
}

.closeBtn {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
  height: 32px;
  margin-left: 17px;
  font-size: 15px;
  border-radius: 8px;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  font-weight: 600;
  margin-top: 25px;
}

.closelBtn:hover,
.undoBtn:hover {
  cursor: pointer;
  background-color: var(--color-button-default-hover);
}

.undoBtn {
  color: var(--color-button-text);
  background-color: var(--color-button-default);
  border-radius: 5px;
  border: 1px solid var(--color-button-white-border);
  padding: 3px 7px;
  line-height: calc(1em + 2px);
  height: 28px;
  margin: 0px 0px 7px 0px;
  font-weight: bold;
  transform: none;
}

.field {
  font-weight: 600;
  margin-right: 15px;
}

.button {
  margin-bottom: 50px !important;
  padding-bottom: 20px;
}
