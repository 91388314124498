.cardHeader {
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.textAreaLabel {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.textArea {
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-input-border);
  color: var(--color-input-text);
  border-radius: 8px;
  width: 100%;
  height: 5.5em;
  padding: 5px;
  padding-left: 10px;
  font-size: 15px;

  resize: none;
}

textarea::placeholder {
  color: var(--color-input-placeholder);
}

textarea:focus {
  outline: none;
  border: 2px solid var(--color-input-border-focus);
}

@media (max-width: 1280px) {
  .textAreaLabel,
  .textArea {
    font-size: 14px;
  }
}
