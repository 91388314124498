.container {
  color: var(--color-text);
  display: grid;
  grid-template-columns: 15% 38% 22% 13% 10%;
  align-items: center;
  width: 100%;
  font-weight: 300 !important;
  height: 35px;
}

/* Venue item */
.gridItem {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noMargin {
  margin-left: 0px;
}

.button {
  display: flex;
  align-items: center;
  width: 50px;
  height: 25px;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  border-radius: 7px;
  color: var(--color-button-white-text);
  margin-left: auto;
  transform: translateY(-2px);
  font-size: 13px;
  font-weight: 600;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.button:active {
  background-color: var(--color-button-active);
}

@media (min-width: 1340px) and (max-width: 1540px) {
  .gridItem {
    max-width: 280px;
  }
}

@media (max-width: 1339px) {
  .gridItem {
    max-width: 200px;
  }
}
