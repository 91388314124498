.title {
  font-size: 16px;
  font-weight: bold;
}

.row {
  display: flex;
  flex-direction: row;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.visibleToCol span:nth-child(2) {
  /* font-size: 15.5px !important; */
  font-size: 14px;
}

.label {
  font-weight: bold;
  font-size: 15px;
}

.visibleAddButton {
  margin-left: 8px;
  font-size: 15px;
  margin-top: 5px;
}

.col,
.visibleToCol {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 0.7em;
}

.tentative {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 0.7em;
}

.col > .label,
.visibleToCol > .label,
.tentative > .label {
  min-width: 170px;
  max-width: 170px;
}

.visibleToSelect {
  font-size: 14px;
}

.visibleToSelect div {
  width: 12em;
}

.colInput {
  min-width: 200px !important;
  max-width: 575px !important;
  font-size: 15px !important;
  align-items: center !important;
  margin-bottom: 0.7em;
}

.colInput > input {
  padding-top: 5px !important;
}

.inputItem,
.disabled {
  min-width: 230px !important;
  max-width: 230px !important;
  /* margin-bottom: 0.7em !important;  */
  /* height: 28px !important; */
  /* margin: 5px 0; */
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.colInput input {
  font-size: 14px !important;
}

.tentative {
  width: 64%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox div {
  display: flex;
}

.checkbox input {
  background-color: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-border);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  align-self: center;
}

.checkbox input:after {
  border: 1px solid var(--color-checkbox-border-active);
}

.checkbox:checked:after {
  background: var(--color-checkbox-border-active);
}

.viewLabel {
  font-weight: bold;
  font-size: 15px;
}

.textInput {
  font-size: 15px;
  margin-left: 10px;
}

.textValue {
  font-size: 15px !important;
  /* margin-left: 15px; */
}

.colValue {
  margin-bottom: 0.7em;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 200px;
}

.checkbox input[type="checkbox"] {
  transition: opacity 0.3s ease;
}

.displayImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px !important;
  width: 100px !important;
  margin-bottom: 0.7em;
}

.displayImagePreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px !important;
  width: 100px !important;
  margin-bottom: -15px;
}

.imageLabel {
  align-items: center;
  min-width: 170px;
  max-width: 170px;
  font-weight: bold;
  font-size: 15px;
}

.iconContainer {
  min-height: 100px !important;
  min-width: 100px !important;
  display: flex;
  align-items: center;
}

.iconContainer > img {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.icon {
  min-height: 100px !important;
  min-width: 100px !important;
}

.datesSection {
  margin-top: -10px;
}

.badgesList {
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.speakerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: var(--color-button-default);
}
.removeSpeaker {
  cursor: pointer;
}
.addBadgesButton {
  margin: 0 90px 0 12px;
}
.addBadgesButton > div {
  height: 20px !important;
}

.captionInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.7em;
  width: 100% !important;
}

.captionInputWrapper div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.7em;
  width: 100% !important;
}

.captionLabel {
  font-weight: bold;
  min-width: 175px;
}

.viewLabel {
  font-weight: bold;
  min-width: 160px;
}

.captionInput {
  flex-grow: 1;
  width: 100%;
  max-width: none !important;
  margin-bottom: -11px;
}

.captionLabel {
  font-size: 15px;
}
