.container {
  width: 100%;
}

.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  width: 700px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
}

.leftSide {
  font-weight: bold;
  margin-bottom: 10px;
}

.button {
  float: right;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px;
  font-weight: bold;
  margin-left: 22px;
  margin-top: 18px;
  margin-bottom: 15px;
}

.createButton {
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  margin-right: 7px;
}

.createButton:hover {
  background-color: var(--color-button-default-hover) !important;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}
.button:active {
  filter: invert(10%);
}
