.container {
  width: 100%;
  /* background-color: var(--color-card-bg);
  color: var(--color-card-text); */
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header {
  font-size: 28px;
  padding-left: 8px;
}

.optionSelectContainer {
  margin-right: 18px;
  order: 1;
  height: auto;
  width: 148px;
  align-items: center;
  margin-top: 5px;
}

.optionSelectContainer button {
  font-size: 16px;
  height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  padding: 4px 20px;
  align-items: center;
}

.subContainer {
  position: sticky;
  top: -3%;
  width: 100%;
  background-color: var(--color-bg);
  z-index: 200;
  padding-top: 0.5rem;
}

.selectContainer {
  margin-top: 36px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.selectLabel {
  margin-bottom: 8px;
  margin-left: 10px;
  font-weight: bold;
}

.selectDiv {
  display: flex;
  width: 80%;
}

.button {
  border: 1px solid var(--color-button-accent-border);
  background: var(--color-button-accent);
  padding: 4px 25px;
  height: auto;
  width: auto;
  color: var(--color-button-text);
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  position: absolute;
  right: 22px;
  top: 60px;
}

.reportsContainer {
  /* padding: 20px;
  border-radius: 10px;
  border: 3px solid var(--color-border);
  background-color: var(--color-card-bg);

  width: 100%;
  min-height: 350px;
  height: auto; */
}

@media (max-width: 1540px) {
  .optionSelectContainer {
    margin-top: 5px;
    margin-right: 10px;
  }

  .selectContainer {
    width: 100%;
  }

  .filterContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
    width: 100%;
  }

  .selectDiv {
    width: 100%;
    padding-bottom: 20px;
  }

  .smallViewport {
    margin-bottom: 20px;
  }

  .button {
    top: -15px;
    right: 12px;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.loaderText {
  display: flex;
  font-weight: bold;
  color: var(--color-text-accent);
}

.noEventsMessage {
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
}
