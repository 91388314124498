.container {
  /* display: flex; */
  width: 100%;
  position: relative;
}

.inputGroup {
  display: flex;
  align-items: center;
  position: relative;
}

.toggleButton {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-f);
  color: #fff;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 34px;
  cursor: pointer;

  border: none;
  outline: none;

  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.popOverMenu {
  display: flex;
  align-items: stretch;
  min-width: 350px;
  max-width: 100%;
  background-color: var(--color-form-bg);
  overflow: hidden;
}

.popOverMenu.open {
  opacity: 1;
  pointer-events: all;
}

.section {
  flex: 0 0 50%;
}

.section.night {
  background-color: var(--color-card-bg);
}

.section > header {
  text-align: center;
  padding: 0.5em;
}

.section > header h3 {
  font-size: 1em;
}

.section ol {
  list-style: none;
}
