.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.limit {
  display: flex;
  align-items: center;
}

.pages {
  display: flex;
  align-items: center;
  background-color: var(--color-form-bg);
  padding: 0 6px;
  border-radius: 8px;
  height: 36px;
}

.pageSelectLabel {
  padding-left: 0.5em;
  color: var(--color-text-gray);
}

.pageLabel {
  padding: 0 1em;
  font-weight: bold;
}

.buttonIcon {
  width: 14px;
  height: 14px;
  
}

.buttonIcon > svg {
  width: 100%;
  height: 100%;
  stroke-width: 3;
}

.paddedButton {
  margin-right: 0.5em;
}