.container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 30% 70%;
}

.label {
  margin-top: auto;
  margin-bottom: auto;

  font-size: 15px;
  font-weight: bold;
}

.selectDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: var(--color-input-bg);
  border: 2px solid var(--color-input-border);
  border-radius: 0.5em;
  opacity: 1;
  padding: 0.3em 0.1em 0.4em 0.8em;
  height: 30px !important;
  position: relative;
  white-space: nowrap;

  text-overflow: ellipsis;
  font-size: 1em;
  /* color: #000000; */
  color: var(--color-text);
  cursor: pointer;
  user-select: none;
}

.selectDropdown:enabled .selectChevron {
  position: absolute;
  width: fit-content;
  max-height: 24px;
  right: 6px;
  bottom: 50%;
  transform: translateY(50%);
}

.selectDropdown:disabled .selectChevron {
  position: absolute;
  width: fit-content;
  right: 6px;
  bottom: 50%;
  transform: translateY(50%);
}

.selectOptions {
  display: flex;
  flex-direction: column;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 1.5px solid var(--color-input-border);
  border-radius: 0.5em;
  opacity: 1;
  position: absolute;
  width: 83%;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  top: calc(100%);
  right: 0;
}

.selectHomesiteOptions {
  display: flex;
  flex-direction: column;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 1.5px solid var(--color-input-border);
  border-radius: 0.5em;
  opacity: 1;
  position: absolute;
  width: 70%;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  top: calc(100%);
  right: 0;

}

.selectOptionsRight {
  display: flex;
  flex-direction: column;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-input-border);
  border-radius: 0.5em;
  opacity: 1;
  position: absolute;
  padding: 0.4em 0;
  width: auto;
  min-width: 100%;
  z-index: 100;
  right: 0px;
}

.selectMenuOption:hover {
  background: var(--color-dropdown-bg-hover);
  color: var(--color-dropdown-hover-text);
}

.selectMenuOption {
  text-decoration: none;
  border: none;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  text-align: left;
  letter-spacing: 0px;
  color: var(--color-text);
  opacity: 1;
  font-size: 1em;
  width: 100%;
  height: 2.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.scrollable {
  max-height: 200px;
  overflow-y: auto;
}
