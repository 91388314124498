.modal {
  background-color: var(--color-card-bg);
  overflow-y: scroll;
  width: 1000px;
}

.modal::-webkit-scrollbar {
  /* width: 5px; */
  /* width: 10px; */
}

.modal::-webkit-scrollbar-thumb {
  /* background-color: var(--color-table-row-even-bg); */
  /* background-color: #5a5a5a;
  border-radius: 5px; */
}

.modal > div:first-child {
  text-transform: uppercase;
  padding: 15px 0 0;
  margin-left: 8px;
  border-bottom: none;
}

.modal > div:first-child > div {
  display: none;
}

.bold,
.flex p {
  font-weight: bold;
}

.payment-header {
  display: flex;
  justify-content: space-between;
}

/* Modal Body */
.modal > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
}

.modal > div:last-child > * > * {
  padding: 8px 16px;
}

.flex {
  display: flex;
}

.flex:not(.credit-card-select) {
  background-color: var(--color-table-row-even-bg);
  font-size: smaller;
  justify-content: space-between;
}

.modal ul {
  list-style: none;
}

/* Events */
.events {
  border: 3px solid var(--color-border);
  /* min-width: 575px; */
}

.events .flex {
  min-width: 768px;
}

.events .flex > p:first-child {
  width: calc(100% * (250 / 600) - (6px / 4));
  padding-right: 8px;
}

.events .flex > p:nth-child(2) {
  width: calc(100% * (150 / 600) - (6px / 4));
  padding-right: 10px;
}

.events .flex > p:nth-child(3) {
  width: calc(100% * (190 / 600) - (6px / 4));
}

.events .flex > p:last-child {
  margin-left: auto;
  margin-right: -6px;
  min-width: calc(100% * (50 / 600) - (6px / 4));
  white-space: nowrap;
}

.events ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
  white-space: nowrap;
  height: 152px;
}

.events ul::-webkit-scrollbar {
  width: 16px;
  /* background-color: var(--color-table-row-even-bg); */
}

.events ul::-webkit-scrollbar-thumb {
  background-color: var(--color-table-row-even-bg);
}

.events ul li:last-child {
  padding-bottom: 4px;
}

/* Cart */
.cart > ul {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cart .flex > p:first-child {
  width: 40%;
}

.cart .flex > p:nth-child(2) {
  margin-left: -20px;
  width: calc(0.2 * 60%);
}

.cart .flex > p:nth-child(3) {
  width: calc(0.6 * 60%);
  margin-left: 20px;
  margin: 0 20px 0 24px;
}

.cart .flex > p:last-child {
  width: calc(0.15 * 60%);
}

/* Payment */

/* Select card */
.payment .flex > *:nth-child(2) {
  width: 40%;
  padding-left: 35px;
}

/* cash payment*/
.payment .flex > *:nth-child(3) {
  flex-grow: 1;
  margin-left: -80px;
  /* flex-wrap: nowrap; */
}

.cash-total-due {
  margin-left: 15px;
}

.cash-total-due,
.paid {
  display: flex;
  justify-content: space-around;
  color: var(--color-button-save);
}

/* .total {
  padding-left: 120px;
} */

.credit-card-select {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
}

/* .credit-card-select {
  justify-content: space-between;
  align-items: center;
} */

.credit-total {
  align-items: last baseline;
}

.credit-card-option li:hover {
  background-color: var(--color-form-bg) !important;
}

.button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0 15px 0 !important;
}

.button-group button + button {
  margin-left: 16px;
}

.button-group button {
  text-transform: uppercase;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 32px;
  /* width: 94px; */
  width: auto;

  font-size: 15px;
  font-weight: bold;
  margin-left: 22px;
  margin-top: 35px;
  margin-bottom: 15px;
}

.button-group button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.buttonRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.button-cancel-create {
  display: flex;
  justify-content: flex-end !important;

  margin-left: auto;
}

.addNoteBtn {
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default-border);
  background-color: var(--color-button-black-bg);
  border-radius: 8px;
  width: 90px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.placeOrderBtn,
.cancelBtn {
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default-border);
  background-color: var(--color-button-black-bg);
  border-radius: 8px;
  width: 70px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addNoteBtn:hover,
.cancelBtn:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
  color: var(--color-button-text-hover);
}

.placeOrderBtn {
  width: 100px;
  margin-left: 12px;
  border: 1px solid var(--color-button-cart-order);
  color: var(--color-button-text);
  background-color: var(--color-button-cart-order);
}

.placeOrderBtn:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
  color: var(--color-button-cart-order);
}

.button-container {
  margin-left: -20px;
}

.button-add {
  margin-left: -20px;
  background-color: var(--color-button-cart-order) !important;
  border: 1px solid var(--color-button-cart-order);
  padding: 0 12px;
}

.noteModalContainer {
  width: 25%;
}

.noteModal {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noteContainer {
  margin: -12px 0 12px;
  min-width: 400px;
  height: 200px;
  padding: 0 12px;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  /* box-sizing: border-box; */
  /* background-color: #fafafa; */
  /* outline: none; */
  /* transition: border-color 0.2s ease; */
}

.noteArea:focus {
  border-color: var(--color-input-note-border-focus);
  box-shadow: 0 0 5px var(--color-input-note-shadow-focus);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.saveButton,
.cancelButton {
  border-radius: 8px;
  width: 60px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelButton {
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default-border);
  background-color: var(--color-button-black-bg);
}

.saveButton {
  margin-left: 12px;
  border: 1px solid var(--color-button-cart-order);
  color: var(--color-button-text);
  background-color: var(--color-button-cart-order);
}

.cancelButton:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
  color: var(--color-button-text-hover);
}

.saveButton:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
  color: var(--color-button-text-hover);
}

.customAlert {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  padding: 0.75rem 1.25rem;
  margin: 25px auto;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: var(--color-text-success);
  background-color: var(--color-background-color-success);
  border-color: var(--color-border-success);
  font-size: 16px;
  width: 80%;
  max-width: 90%;
}

.warningAlerts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  margin: 3px 0px 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
}

.clearCartWarningContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* gap: auto; */
}

.addVenue,
.addAddress,
.clearCart {
  background-color: var(--color-button-remove);
  border: none;
  width: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-button-text);
  justify-self: right;
}

.addAddress {
  width: 90px;
}

.addVenue {
  width: 80px;
}

.alertSuccess {
  color: var(--color-text-success);
  background-color: var(--color-background-color-success);
  border-color: var(--color-border-success);
}

.alertError {
  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);
}

.alertBlue {
  color: var(--color-text-info);
  background-color: var(--color-background-color-info);
  border-color: var(--color-border-info);
}

/* .cash {
  background-color: #0db65a;
  color: white;
  border-color: #0db65a;
} */

.button-group button:last-child {
  background-color: var(--color-button-default);
  border-color: 1px solid var(--color-button-default) !important;
  color: var(--color-button-text);
  margin-right: 7px;
}

.button-group button:last-child:hover {
  background-color: var(--color-button-default-hover) !important;
}
.closeBtn {
  text-transform: uppercase;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  margin-top: -20px;
  /* Adjust top and bottom margins as needed, auto for left and right */
}
.closeBtnContainer {
  display: flex;
  justify-content: center;
  /* other styles as needed */
}
.hidden {
  display: none;
}

.payment-total-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -30px;
}

.subtotal-margin {
  display: flex;
  flex-direction: column;

  align-items: flex-end;
}

.label {
  font-weight: bold;
}

.amount {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 195px;
  line-height: 35px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.itemError {
  color: var(--color-text-red);
  font-weight: lighter;
  font-size: 14px;
  margin-top: 3px;
}

.globalError {
  color: var(--color-text-red);
  font-weight: lighter;
  font-size: 14px;
  margin-top: -10px;
}

.errorMessage {
  color: var(--color-text-red);
  font-weight: lighter;
  font-size: 14px;
  margin-top: -2px;
}

.card-input.error {
  border: 1px solid var(--color-text-red);
}

/* Transitions */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 0.3s ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.list {
  max-height: 525px !important;
  overflow-y: auto;
  /* scrollbar-width: thin; */
  /* scrollbar-color: #888 var(--color-card-bg); */
}
.remove {
  background-color: var(--color-button-remove);
  border: none;
  width: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-button-text);
  /* margin-left: auto; */
}
.alerts {
  margin-bottom: -3%;
}
/* NewOrderModal.module.css */
.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1rem;
  position: relative;
}

.alert-danger {
  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);
}

.alert-info {
  color: var(--color-text-info);
  background-color: var(--color-background-color-info);
  border-color: var(--color-border-info);
}

.singleCardOption {
  margin-left: -30px !important;
  font-size: small;
  border: 1px solid var(--color-border);
  width: 150px;
}
.total {
  display: flex;
  justify-content: flex-end;
}

.noEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* font-weight: bold; */
  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);
  padding: 0.75rem 1.25rem;
  margin-top: 2rem;
  margin-bottom: -5rem;
  margin-left: auto;
  /* Center the element horizontally */
  margin-right: auto;
  /* Center the element horizontally */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 80%;
  /* font-size: 1rem; */
  /* position: relative; */
}

/* Filter */
.filterByText {
  margin-bottom: -20px;
  margin-top: 8px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 15px;
}

.filterDiv {
  display: flex;
  width: 100%;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  height: auto;
}

.loaderBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.invisible {
  display: none;
}

.totalSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-self: end;
  align-self: flex-end;
  width: 20%;
  max-width: 20%;
}

.pricingSubtotal {
  margin: -5px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid black; */
}

.itemDivider {
  border-bottom: 1px solid var(--color-button-default-border);
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.pricingTax {
  margin: -5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-button-default-border);
}

.pricingTotal {
  margin: 0px -5px -5px;
  display: flex;
  justify-content: space-between;
}

.warningMargin {
  margin-bottom: 1rem;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonGroup > button {
  margin-right: 5px;
}

.addToCart {
  background-color: var(--color-button-cart-order);
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-button-text);
  height: 30px;
}

.responseRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* padding: 24px; */
}

.event {
  text-transform: uppercase;
  width: 30%;
  /* height: calc(15px + (0.332 * 15px) + (0.334667 * 15px)); */
  /* margin-top: 7.5px; */
  /* padding-bottom: 75px; */
}

.eventName {
  font-weight: bold;
}

.event p {
  width: fit-content;
  padding: 0 1px !important;
}

.divider {
  border-bottom: 1px solid var(--color-button-default-border);
  width: 100%;
}

.responseAlertSuccess {
  color: var(--color-text-success);
  background-color: var(--color-background-color-success);
  border-color: var(--color-border-success);
}

.responseAlertError {
  color: var(--color-text-error);
  background-color: var(--color-background-color-error);
  border-color: var(--color-border-error);
}

.responseAlertBlue {
  color: var(--color-text-info);
  background-color: var(--color-background-color-info);
  border-color: var(--color-border-info);
}

.response {
  width: 70%;
}

.responseAlerts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  margin: 0 !important;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  min-width: 70%;
}

.goBackBtn {
  margin: 0;
  padding: 0 !important;
  height: 40px;
}

@media (max-width: 768px) {
  /* Adjust the max-width value as needed */
  .events .flex > p:nth-child(2) {
    margin-left: -80px;
    /* Adjust this value to control the spacing */
  }
  .events .flex > p:nth-child(3) {
    margin-left: -40px;
    /* Adjust this value to control the spacing */
  }
}
