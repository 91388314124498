.container {
  width: 100%;

  position: relative;
  height: auto;
}

.border {
  border-radius: 100px;
}

.paginationContainer {
  position: absolute;
  bottom: 20px;
}

/* .rulesetListContainer {
  overflow-y: auto;
  height: 100%;
  cursor: grab;

  -ms-overflow-style: none; 
  scrollbar-width: none;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
/* .rulesetListContainer::-webkit-scrollbar {
  display: none;
} */
