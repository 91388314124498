.radioButton {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  position: relative;
}

.radioButton:after {
  position: relative;
  border: 1px solid var(--color-checkbox-border-active);
  border-radius: 50%;
  content: "";
  display: inline-block;
  visibility: visible;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-bottom: 8px;
  margin-left: 2px;
}

.radioButton:checked:after {
  background: var(--color-checkbox-border-active);
}

.radioButtonLabel {
  position: absolute;
  font-size: 15px;

  line-height: 16px;
}

.radioButtonContainer {
  color: var(--color-text);
  font: normal normal 300 10px/12px;
  display: block;
  align-items: center;
}

.radioCheckmark {
  position: absolute;
  left: 0;
  right: 0;
  /* top: -7px; */
  /* vertical-align: middle; */
  margin-left: 2px;
  margin-right: auto;
}

.radioCheckmark:checked + .icon {
  visibility: visible;
}

.radioCheckmark > svg {
  width: 14px;
  stroke-width: 2;
  stroke: var(--color-checkbox-icon);
}

.relativePosition {
  position: relative;
}
