.container {
  width: 100%;
}

.container > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
}

.dateLabel {
  font-weight: bold;
}

.navButton {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: var(--color-datepicker-text);
}

.navButton:hover {
  background-color: var(--color-datepicker-selected-bg);
  color: var(--color-datepicker-text);
}

.month {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.dayLabels {
  display: flex;
  flex-flow: row nowrap;
}

.dayLabels > span {
  flex: 1 1 100%;
  text-align: center;
  font-size: 0.9em;
}

.week {
  display: flex;
  flex-flow: row nowrap;
}

.day {
  flex: 1 1 100%;
  padding: 0.6em 0;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
}

.day:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.day:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.day:hover {
  background-color: var(--color-datepicker-selected-bg);
  color: var(--color-datepicker-selected-text);
  font-weight: bold;
}

.day.outsideOfMonth {
  opacity: 0.3;
}

.day.outsideOfMonth:hover {
  opacity: 1;
}

.day.selectedDay {
  background-color: var(--color-datepicker-selected-bg);
  color: var(--color-datepicker-selected-text);
  border-radius: 4px;
}
