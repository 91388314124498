.container {
  width: 100%;
  max-height: auto;
  min-width: 768px;
  min-height: 250px;
}

.modal {
  position: absolute;
  right: 50%;
  top: 2.5%;
  transform: translate(50%, 0%);
  width: 900px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  /* z-index: 201; */
  height: auto;
  max-height: 95vh;
  min-height: 30vh;
  padding-bottom: 50px;
  /* overflow-y: scroll; */
}

.flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
}

.labelSelectGroup {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 45%;
  padding-bottom: 50px;
}

.label {
  font-weight: bold;
  font-size: 15px;
  text-align: right;
  padding-right: 15px;
  white-space: nowrap;
}

.container button {
  height: 28px;
}

.section,
.section .inputRow div {
  font-size: 15px;
}

.addButton {
  margin-top: 20px;
}

.saveBtn {
  font-size: 15px;
}

.saveBtn * {
  margin-top: 5px;
}

.save {
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 15px 20px;
}

.removeBtn {
  margin-left: 25px;
}

.remove {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  opacity: 1;
}

.remove:hover {
  background: var(--color-button-white-hover) 0% 0% no-repeat padding-box;
}

.save:hover {
  background: var(--color-button-default-hover) 0% 0% no-repeat padding-box;
}

.loader {
  margin-top: 50px;
}
