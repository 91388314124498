.eventHeader {
  margin-top: 35px;
  padding: 0 1em;
}

.ibo-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: -12px;
}

.headerEventType {
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1;
}

.checkboxGroup {
  display: flex;
  flex-flow: row nowrap;
  height: var(--line-height);
  align-items: center;
  margin: 5px;
}

.divider {
  width: 0;
  border-right: 3px solid var(--color-border);
  margin-right: 1.5em;
  margin-left: 0.5em;
}

.selected > td {
  background-color: var(--color-surface-yellow) !important;
  color: var(--color-card-text);
}

.containerEventName {
  grid-row: 1;
  grid-column: 1 / 4;
}

.containerStartDate {
  grid-row: 2;
  grid-column: 1;
  text-align: right;
}

.containerEndDate {
  grid-row: 2;
  grid-column: 3;
  text-align: left;
}

.tableHeight {
  max-height: 338px;
  overflow-y: auto;
}

@media screen and (min-width: 700px) {
  .containerEventName {
    margin-right: 0.7em;
  }
  .containerStartDate {
    text-align: unset;
  }
  .containerEndDate {
    text-align: unset;
  }

  .containerSelectTicketTypes {
    margin-right: auto;
    padding-right: 5rem;
  }

  .containerBtnSpecialNeeds,
  .containerBtnDelete {
    display: flex;
    align-items: center;
  }
}
