/* .ticketGroupItemContainer {
  font-size: 15px;
  display: grid;
  grid-template-columns: 12% 19% 14% 20% 20% 11% auto;
  align-items: center;
  margin-left: 2em;
} */

.ticketGroupItemContainerV1 {
  font-size: 15px;
  display: grid;
  grid-template-columns: 35% 35% 23%;
  align-items: center;
  margin-left: 2em;
}

.price {
  /* margin-right: 4em; */
  text-align: flex-end;
}

.editButton {
  color: var(--color-button-white-text) !important;
  background-color: var(--color-button-white-bg);
  border-radius: 5px;
  border: 1px solid var(--color-button-white-border);
  padding: 3px;
  line-height: calc(1em + 2px);
  height: auto;
  width: 45px;
  font-weight: bold;
  transform: none;
}

.editButton:hover {
  background-color: var(--color-button-white-hover);
}

.ticketGroupItemContainerV2 {
  min-height: 130px;
  font-size: 15px;
  display: grid;
  grid-template-columns: 15% 6% 6% 15% 10% 7.5% 31.5% 5% 5%;
  margin-bottom: 10px;
  align-items: center;
}
.ticketGroupItemContainerV2Sub {
  min-height: 130px;
  font-size: 15px;
  display: grid;
  grid-template-columns: 15% 6% 6% 13% 10% 15% 30% 5%;
  margin-bottom: 10px;
  align-items: center;
}
.subItem {
  display: flex;
  width: 75%;
  justify-content: flex-start;
  align-items: center;
}
.subItemName {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
}
.ticketGroupItemContainerV2Major {
  min-height: 130px;
  font-size: 15px;
  display: grid;
  grid-template-columns: 14% 5.5% 10% 33% 15% 15% 8%;
  margin-bottom: 10px;
  align-items: center;
}

.groups {
  display: flex;
}

.groupsContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 125px 1fr;
}

.addButtonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.groupsGrid {
  display: grid;
  gap: 2px;
}

.groupItem {
  display: flex;
  flex-direction: row;
}

.removeGroup {
  margin-left: 7px;
  cursor: pointer;
  font-weight: bold;
}

.addGroupsButton > div > span {
  margin-left: 6px;
}

.editCreditTypesButton {
  font-size: small;
  max-width: 35px;
  max-height: 25px;
}
.removeButton {
  font-size: small;
  max-width: 60px;
  max-height: 25px;
}

.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.groupsList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 70%;
  justify-content: flex-start;
  margin-right: 12px;
}

.removeGroup {
  cursor: pointer;
  margin-left: 2px;
}

.selectContainer > div {
  min-width: 75px;
  max-width: 110px;
  line-height: 21px !important;
}

/* Credit Types */
.creditTypesContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.creditTypesItem {
  display: flex;
  flex-direction: row;
  gap: 7px;
  cursor: pointer;
}

/* Tandem Ticket */
.tandemTicketContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.tandemTicket {
  display: flex;
  flex-direction: row;
  gap: 7px;
  cursor: pointer;
}

.editTandemTicketButton {
  font-size: small;
  max-width: 35px;
  max-height: 25px;
}

.input {
  border-width: 2px !important;
}
