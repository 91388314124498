.container {
  width: 100%;
  /* background-color: var(--color-card-bg);
  color: var(--color-card-text); */
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header {
  font-size: 28px;
  padding-left: 8px;
}

.optionSelectContainer {
  margin-right: 18px;
  order: 1;
  height: auto;
  width: 148px;
  align-items: center;
  margin-top: 5px;
}

.optionSelectContainer button {
  font-size: 16px;
  height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  padding: 4px 20px;
  align-items: center;
}

.subContainer {
  position: sticky;
  top: -3%;
  width: 100%;
  background-color: var(--color-bg);
  z-index: 200;
  padding-top: 0.5rem;
}

.filterAndNewContainer {
  margin-top: 36px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.filterByText {
  margin-bottom: 8px;
  margin-left: 10px;
  font-weight: bold;
}

.filterDiv {
  display: flex;
  width: 80%;
}

.button {
  border: 1px solid var(--color-button-accent-border);
  background: var(--color-button-accent);
  padding: 4px 25px;
  height: auto;
  width: auto;
  color: var(--color-button-text);
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  position: absolute;
  right: 22px;
  top: 60px;
}

/* View numbers */
.viewCountContainer {
  width: 18%;
  display: flex;
  justify-content: space-between;
  float: right;
  margin-top: -47px;
  margin-bottom: 8px;
  user-select: none;
  font-weight: bold;
  color: var(---color-card-text);
  margin-right: 5px;
  cursor: pointer;
  z-index: 201;
  position: relative;
}

.viewCountItem {
  text-align: end;
}

.viewCountItem:hover {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.eventsGrid {
  display: grid;
  grid-template-columns: 9% 40% 20% 18% auto;
  width: 100%;
  line-height: 50px;
  padding-left: 28px;
  padding-right: 30px;
  border-radius: 8px;
  background-color: var(--color-card-header-bg);
  font-weight: bold;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 25px;
}

.paginationControls {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.eventCard {
  background-color: var(--color-card-bg);
  max-height: 500px;

  /* overflow-y: auto; */
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.loaderText {
  display: flex;
  font-weight: bold;
  color: var(--color-text-accent);
}

@media (max-width: 1540px) {
  .optionSelectContainer {
    margin-top: 5px;
    margin-right: 10px;
  }

  .filterAndNewContainer {
    width: 100%;
  }

  .filterContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
    width: 100%;
  }

  .filterDiv {
    width: 100%;
    padding-bottom: 20px;
  }

  .smallViewport {
    margin-bottom: 20px;
  }

  .button {
    top: -15px;
    right: 12px;
  }

  .viewCountContainer {
    width: 20%;
    /* margin-top: 0px;
    margin-bottom: 22px; */
    margin-top: -25px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .viewCountItem {
    text-align: end;
  }
}

@media (min-width: 1430px) and (max-width: 1540px) {
  .eventsGrid {
    grid-template-columns: 11% 35% 22% 20% auto;
  }
}

@media (min-width: 1340px) and (max-width: 1429px) {
  .eventsGrid {
    grid-template-columns: 11% 35% 20% 20% auto;
  }
}

@media (min-width: 1280px) and (max-width: 1339.98px) {
  .eventsGrid {
    grid-template-columns: 12% 35% 23% 17% auto;
  }
}

@media (min-width: 1201px) and (max-width: 1279px) {
  .eventsGrid {
    grid-template-columns: 13% 35% 23% 17% auto;
  }

  .gridItem {
    font-size: 15px;
  }

  .viewCountContainer {
    width: 28%;
  }
}

@media (max-width: 1200px) {
  .eventsGrid {
    grid-template-columns: 14% 30% 25% 17% auto;
  }

  .gridItem {
    font-size: 14px;
  }

  .viewCountContainer {
    width: 28%;
    font-size: 15px;
  }
}

.noEventsMessage {
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
}
