.container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 2em;
  width: 11em;
  align-items: center;
}

.icon {
  color: var(--color-button-text);
  background-color: var(--color-button-default);
  width: 21px;
  height: 23px !important;
  border-radius: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 2px;
  border: transparent;
}

.input {
  height: 100%;
  font-size: 10px;
  padding-left: 1.7rem;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
  padding-left: 30px;
  color: var(--color-input-text);
}

.input::placeholder {
  font-size: 12px;
  color: var(--color-input-placeholder);
}

.container input[type="text"]:focus {
  outline: none;
}
