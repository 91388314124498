.speakerItemRow {
  display: grid;
  grid-template-columns: 4% 49% auto;
  line-height: 35px;
  /* padding: 7px 4px; */
  vertical-align: center;
  text-align: left;
  font: normal normal 300;
  align-items: center;
  /* font-size: 13px; */
  font-size: 14px;

  color: var(--color-text);
}

.checkbox input {
  background: var(--color-checkbox-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-checkbox-border);
  border-radius: 3px;
  width: 14px;
  height: 14px;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.speakerItemRow .item {
  /* padding-top: 2px; */
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type,
.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speakerItemRow .name a {
  color: var(--color-link);
  text-decoration: underline !important;
}

.speakerItemRow .name a:hover {
  color: var(--color-link-hover);
}
