.container {
  width: 100%;
}

.pageHeader {
  width: 100%;
  padding: 1.5rem 0rem 1.5rem 0.5rem;
  font-size: 1.2em;
  font-family: var(--font-family);
  position: sticky;
  top: -3%;
  width: 100%;
  z-index: 200;
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--color-border);
}

/* .selectDiv {
  width: 150px;
} */

.jobCode {
  font-size: 16px !important;
  margin-right: 1rem;
}

.loader {
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.optionSelectContainer {
  margin-right: 18px;
  order: 1;
  height: auto;
  width: 148px;
  align-items: center;
  margin-top: 5px;
  font-size: 16px;
}

.optionSelectContainer button {
  font-size: 16px;
  height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  padding: 4px 20px;
  align-items: center;
}

.headerRight {
  float: right;
  display: flex;
}

.eventTime {
  font-size: 15px;
  margin-bottom: 1rem;
}

.pageContent {
  width: 100%;
  margin-top: 2rem;
}

.editEventButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.editButton {
  height: 32px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 5px;
}

.editButton:hover {
  background-color: var(--color-button-default-hover);
}

.cardContainer {
  margin: 2em 0;
}
