.container {
  width: 100%;
}

.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  width: 450px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
  font-size: 16px;
}

.modal svg {
  /* color: var(--color-button-default); */
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
}

.grid2 {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 85px;
  margin-bottom: 15px;
  font-size: 15px;
}

.gridItem {
  line-height: 30px;
}

.location {
  line-height: 52px;
}

.grid {
  display: grid;
  grid-template-columns: 137px auto;
  margin-top: 2px;
  margin-bottom: 5px;
}

.button {
  float: right;
  background-color: var(--color-button-default);
  color: var(--color-button-text) !important;
  border: 2px solid var(--color-button-default);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px;
  font-weight: bold;
  margin-left: 22px;
  margin-top: 18px;
  margin-right: 7px;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-default-hover);
}

.button:active {
  filter: invert(10%);
  color: var(--color-button-text);
}

.onSiteOrVirtualIndicator {
  float: right;
  background-color: var(--color-button-yellow);
  color: var(--color-text);
  border: none;
  border-radius: 8px;
  height: 28px;
  width: 88px;
  margin-left: 2px;
  margin-top: 18px;
  transform: translateY(-5px);
  font-size: 14px;
}

.onSiteOrVirtualIndicator:hover {
  cursor: pointer;
}

.onSiteOrVirtualIndicatorOff {
  background-color: var(--color-bg);
  color: var(--color-button-disabled);
}

.disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.chooseDateModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.labelAndPickerContainer {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
  margin-right: -50px !important;
  color: white;
}

.labelAndPickerContainer input {
  height: 23px;
  width: 120px;
  font-size: 13.5px;
  background: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
}

.labelAndPickerContainer button {
  font-size: 11px;
  font-weight: normal;
}

.datePickerContainer {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
  margin-right: -50px !important;
  margin: 6px 0;
}

.datePickerContainer input {
  height: 23px;
  width: 120px;
  font-size: 13.5px;
  background: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
}

.datePickerContainer button {
  font-size: 11px;
  font-weight: normal;
}

.saveBtn {
  float: right;
  background-color: var(--color-button-default);
  color: var(--color-button-text) !important;
  border: 2px solid var(--color-button-default);
  border-radius: 8px;
  height: 32px;
  width: 94px;
  font-size: 15px;
  font-weight: bold;
}

.saveBtn:hover {
  cursor: pointer;
  background-color: var(--color-button-default-hover);
}

.grid3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.noEvents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border: 2px solid var(--color-border);
  border-radius: 6px;
}

.virtualEventList {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow: auto;
  border: 2px solid var(--color-border);
  border-radius: 6px;
}

.virtualEvent {
  display: flex;
  flex-direction: row;
  margin: 6px;
  padding: 6px;
  border-bottom: 1px solid var(--color-border);
  align-items: center;
  justify-content: flex-start;
}

.virtualEventLast {
  display: flex;
  flex-direction: row;
  margin: 6px;
  padding: 6px;
  align-items: center;
  justify-content: flex-start;
}

.divider {
  margin: 12px 0 0;
  border-bottom: 1px solid var(--color-border);
}

.checkboxContainer {
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  margin: 0 0 0 6px;
}

.virtualNote {
  margin: 6px 0 0;
}
