.container {
  width: 100%;
  height: auto;
  padding: 18px;
  padding-left: 17px;
  padding-right: 17px;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  background-color: var(--color-card-bg);
  color: var(--color-text);
}

.container div {
  margin-bottom: 8px;
}

.cardHeader {
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px !important;
  border: 0.5px solid var(--color-border);
}

.button {
  float: right;
  background-color: var(--background-color);
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 27px;
  width: 50px;
  font-size: 13px;
  font-weight: bold;
  margin-right: 5px;
  transform: translateY(-8px);
}

.button:hover {
  cursor: pointer;
}

.button:active {
  filter: invert(10%);
}
