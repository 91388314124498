.container {
  width: 100%;
  height: auto;
}

.cardHeader {
  font-weight: bold;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}
