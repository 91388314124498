.container {
  width: 100%;
  height: auto;
  min-height: 550px;
  position: relative;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.button {
  float: right;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 8px;
  height: 28px;
  width: 126px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 22px;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  filter: invert(10%);
}

.items {
  margin-bottom: 20px;
}

.bold {
  font-weight: bold;
}

.tags {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  width: fit-content;
}

.header {
  display: grid;
  grid-template-columns: 12% 15% 16% 17% 14% 19% auto;
  width: 100%;
  background-color: var(--color-card-header-bg);
  margin-top: 10px;
  padding-left: 15px;
  line-height: 35px;
}

.tableSortItem {
  width: 50%;
}

.paginationControlsContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 20px;
}

.whiteBG {
  background-color: var(--color-card-bg);
}

.slightlyDarkerBackground {
  background-color: var(--color-card-list-bg);
}

@media (min-width: 1620px) and (max-width: 1760px) {
  .header {
    display: grid;
    grid-template-columns: 12% 16% 16% 16% 15% 16% auto;
  }
}

@media (min-width: 1541px) and (max-width: 1619px) {
  .header {
    display: grid;
    grid-template-columns: 12% 15% 16% 16% 16% 15% auto;
  }
}

@media (min-width: 1390px) and (max-width: 1540px) {
  .header {
    display: grid;
    grid-template-columns: 13% 15% 16% 16% 16% 14.5% auto;
  }

  .tableSortItem {
    font-size: 0.92em;
    margin-right: -5px !important;
    width: 45%;
  }

  .tableSortItem > div svg {
    margin-left: -5px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1389.98px) {
  .header {
    display: grid;
    grid-template-columns: 13% 14% 15% 16% 17% 16% auto;
  }

  .tableSortItem {
    font-size: 0.85em;
  }

  .tableSortItem > div svg {
    margin-left: -5px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1279.98px) {
  .header {
    display: grid;
    grid-template-columns: 14% 13% 13% 16% 19% 15% auto;
  }

  .tableSortItem {
    font-size: 0.8em;
  }

  .tableSortItem > div svg {
    margin-left: -5px !important;
  }
}

@media (max-width: 1200px) {
  .header {
    display: grid;
    grid-template-columns: 15% 11% 11% 17% 20% 14% auto;
  }

  .tableSortItem {
    font-size: 0.8em;
  }

  .tableSortItem > div svg {
    margin-left: -7px !important;
  }
}
