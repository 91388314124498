.container {
  color: var(--color-text);
  width: 100%;
}

.title {
  margin-bottom: 16px;
  font-size: 1.2em;
}

.filterRuleset {
  margin-bottom: 16px;
}

.rulesetList {
  padding-bottom: 20px;
}

.rulesetHeader {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-card-header-bg);
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
  font-weight: bold;
}

.subContainer {
  background-color: var(--color-bg);
  position: sticky;
  top: -3%;
  width: 100%;
  z-index: 200;
  padding-top: 1.2rem;
}
