.flex {
  display: flex;
}

.flex span {
  color: var(--color-text-accent);
}

.container {
  flex-direction: column;
  transition-delay: 5s;
  border-radius: 8px;
  position: relative;
}

.container > * {
  padding-left: 0.566667em;
  padding-right: 0.566667em;
  line-height: 27px;
}

.display-none {
  height: 0;
  opacity: 0;
  position: absolute;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

#custom-options {
  margin-top: -10px;
  color: var(--color-text);
  background-color: var(--color-bg);
  border: 1px solid var(--color-input-border);
  list-style: none;
  font-size: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  row-gap: 1px;
  transition: all 0.3s ease-out, padding 0s, border-width 0s, width 0s;

  position: absolute;
  z-index: 100;
  padding: 0;
}

#custom-options.none {
  visibility: hidden;
  opacity: 0;
  color: transparent;
  position: absolute;
  width: 0;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  border-width: 0;
  transition: all 0.3s ease-out, padding 0s ease-out 0.31s,
    border-width 0s ease-out 0.31s, width 0s ease-out 0.31s;
}

#custom-options li {
  cursor: pointer;
  padding: 0px 10px 0px 10px;
  margin: 0px;
  margin-left: 5px;
}

#custom-options li:hover {
  background-color: var(--color-dropdown-bg-hover);
}

#custom-options li:first-of-type {
  margin-top: 5px;
  /* margin-left: 5px; */
}

#custom-options li:last-of-type {
  padding-bottom: 5px;
}

#custom-options li:last-of-type:hover {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dropdown-button,
.dropdown-button:disabled {
  padding-top: 0.332em;
  padding-bottom: 0.334667em;
  border-radius: 8px;
  background-color: var(--color-bg);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  font-size: 15px;
  color: var(--color-alt-text);
}

.dropdown-button:disabled {
  color: var(--color-alt-text);
}
.dropdown-button:hover {
  cursor: pointer;
}

.dropdown-button:focus {
  outline: none;
}

.open-placeholder {
  color: var(--color-alt-text) !important;
}

.border,
.dropdown-button:disabled.border {
  border: 3px solid var(--color-border);
}

#custom-options.border {
  border-top: none;
}

.arrow {
  width: 0.861333em;
  height: 0.506em;
  fill: var(--color-alt-text);
}
