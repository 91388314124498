/* .container {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid var(--color-border);
  display: flex;
  align-items: center;
} */

.container {
  background-color: var(--color-form-bg);
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 8px;
  /* padding-bottom: 1em; */
  display: flex;
  align-items: center;
}

.container > *:not(:last-child) {
  margin-right: 0.5em;
}

/* .StyledTabs {
  justify-content: center;
  background-color: #808080 !important;
} */
