/* @import url("http://fonts.cdnfonts.com/css/helvetica-neue-9"); */

.title {
  font-weight: bold;
  font-size: 16px;
}

.container {
  /* color: var(--color-b); */
  border-width: 1px;
}

.form-container,
.form-container .input-container > div,
.event-description > div {
  display: flex;
  /* font-size: 14px; */
  font-size: 15px;
}

.form-container {
  margin-top: 0.75em;
  flex-direction: column;
  row-gap: 0.75em;
}

.input-container span,
.event-description label {
  width: 10em;
}

.input-container span:not(.no-margin),
.event-description label {
  margin-right: 1.3em;
}

.input-container input,
.event-description textarea {
  border-radius: 0.5em;
  flex-grow: 1;
  font-weight: 100;
}

/* .header {
  padding-bottom: 0.8em;
  border-bottom: 0.5px solid var(--color-border);
  margin-bottom: 0.8em;
} */

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.double {
  display: flex;
  justify-content: space-between;
  gap: 2em;
}

.double > div:first-child {
  flex-grow: 1;
}

.double > div:nth-child(2) span {
  width: 7em;
}

.flex,
.button-container > div {
  display: flex;
  align-items: center;
}

.button-container {
  cursor: pointer;
}

.button-container > div > span {
  margin-left: 2.9em;
}

.event-description > div {
  flex-direction: row;
}

.event-description textarea {
  width: auto;
  height: 5.5em;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-input-border);
  color: var(--color-input-text);
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
}

.event-description textarea::placeholder,
.inputItem div input::placeholder {
  color: var(--color-input-placeholder);
}

.event-description textarea:focus,
.inputItem div input:focus,
.date-picker input:focus {
  border: 2px solid var(--color-input-border-focus);
}

.inputItem div input {
  /* height: 28px; */
  border-radius: 8px;
  font-size: 14px;
  font-weight: normal;
  background: var(--color-input-bg);
  color: var(--color-input-text);
}

.label {
  font-weight: bold;
  font-size: 15px;
  min-width: 140px;
}

/* Start Date */

.input-container .picker-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.picker-input {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
}

.date-picker input,
.time-picker input {
  font-size: 13.5px;
  font-weight: normal;
  height: 23px;
  /* width: 145px; */
  width: 120px;

  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.date-picker div {
  height: 22px !important;
  border-radius: 5px !important;
  font-size: 11px;
}

.date-picker div svg {
  height: 16px !important;
  width: 16px !important;
  padding: 0 !important;
  font-size: 14px;
}

.time-picker div {
  height: 22px !important;
}

.time-picker div svg {
  font-size: 14px;
}

.time-picker input {
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.time-picker {
  margin-right: 10px;
}

.time-picker button {
  font-size: 11.5px;
  font-weight: normal;
}

.timezone div {
  font-size: 12px;
  font-weight: normal;
}

.timezone div button {
  width: 60px;
}

.timezone button svg {
  margin-right: -35px !important;
  font-size: 20px;
}

.timezone span svg {
  margin-right: 20px;
}

@media (max-width: 1680px) {
  .end-date-section .label {
    min-width: 170px !important;
  }

  .end-date-section {
    width: 100%;
    margin-top: 0.5em;
    flex-direction: column;
  }

  .dates {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 1100px) and (max-width: 1280px) {
  .input-container span,
  .event-description label {
    width: 11em;
  }

  .label {
    font-size: 14px;
    min-width: 150px;
  }

  .form-container,
  .form-container .input-container > div,
  .event-description > div {
    display: flex;
    font-size: 14px;
  }

  /* .picker-input {
    gap: 5px;
  } */

  .date-picker input,
  .time-picker input {
    font-size: 13px;
    width: 120px;
  }

  .input-container.flex .picker-group > .label:first-child {
    min-width: 153px !important;
  }

  .end-date-section > .label {
    margin-right: 18px;
    margin-top: 15px;
  }

  .double > .label {
    margin-right: 18px;
  }

  .double {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .double > div:first-child {
    width: 100%;
  }

  .double > div:nth-child(2) {
    margin-top: -17px;
    width: 100%;
  }

  .input-container.double > :nth-child(2) input {
    width: 100%;
  }

  .double > div:nth-child(2) span {
    min-width: 11em !important;
  }
}

@media (max-width: 1099.98px) {
  .form-container,
  .form-container .input-container > div,
  .event-description > div {
    font-size: 14px;
  }

  .date-picker input,
  .time-picker input {
    font-size: 13px;
    width: 120px;
  }

  .label {
    font-size: 14px;
    min-width: 140px;
  }

  .picker-input {
    gap: 5px;
  }

  .input-container.flex .picker-group > .label:first-child {
    min-width: 137px !important;
  }

  .end-date-section > .label {
    margin-right: 20px;
  }

  /* .end-date-section > .label {
    margin-right: 18px;
    margin-top: 15px;
  } */

  .double > .label {
    /* margin-right: 18px; */
  }

  .double {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
  }

  .double > div:first-child {
    width: 100%;
  }

  .double > div:nth-child(2) {
    margin-top: -17px;
    width: 100%;
  }

  .input-container.double > :nth-child(2) input {
    width: 100%;
  }

  .double > div:nth-child(2) span {
    min-width: 10em !important;
  }

  .input-container span,
  .event-description label {
    width: 10em;
  }
}
