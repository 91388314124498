.container {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  height: 100%;
}

.textArea {
  color: var(--color-input-text);
  width: 100%;
  font-size: 1em;
  height: 100%;
  resize: none;
  padding-top: 0.812em;
  padding-right: 0.8em;
  padding-bottom: 0.812em;
  padding-left: 0.8em;
  border-radius: 0.5em;
  background-color: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-input-border);

  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar) var(--color-card-bg);
}

.textArea::-webkit-scrollbar-track {
  background-color: var(--color-card-bg);
}
.textArea::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar);
  border-radius: 100px;
}

.label {
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.8em;
}

.container textarea[type="text"]:focus {
  outline: none;
}
