.eventHeader {
  margin-top: 35px;
  padding: 0 1em;
}

.headerEventType {
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1;
}

.sectionTopMargin {
  margin-top: 15px;
}

.buttonsColumn{
  display: flex;
  /* width: 150px; */
  justify-content: flex-end;
}

.buttonsColumn button{
  margin-left: 10px;
}