.container {
  width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  padding: 20px;
  /* padding-bottom: 10px; */
  color: var(--color-text);
}

.menuRow {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.selectDiv {
  flex: 1;
}

.tagRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-size: 12px;
}

.tagRow div > div > div {
  padding: 0.6em 1.2em;
}

.tagRow div > div {
  display: flex;
  flex-direction: row;
  border-radius: 0.5em;
  margin: 0;
  padding: 0;
}

.tagRow div > div > svg {
  transform: scale(2.1);
  /* margin-right: 0.9em; */
}

.tag {
  margin-right: 15px;
}

.tag > div {
  height: 25px;
}

.tag > div > svg {
  transform: scale(1) !important;
}

.none div {
  display: none;
}

.label {
  text-align: left;
  color: var(--color-text);
  opacity: 1;
  font-weight: bold;
  font-size: 14px;
}

.button {
  margin-top: 29px;
}

.button button {
  height: 28px;
  margin-bottom: 3px;
}

.btnEnabled {
  border: transparent;
  border-radius: 0.5em;
  background-color: var(--color-button-default);
  margin-top: -4px;
  padding: 0 1.2em;
  width: 100%;
  font-size: 0.9em;
}

.btnDisabled {
  border: transparent;
  border-radius: 0.5em;
  margin-top: -5px;
  padding: 0 1.2em;
  width: 100%;
  border: 1px solid transparent;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  font-size: 0.9em;
}

.selectSpan {
  font-size: 0.75em;
  margin-top: 5px;
  margin-right: 10px;
  vertical-align: middle;
}

.searchBar {
  font-size: 0.7em;
}

.searchBar input {
  background-color: var(--color-dropdown-bg);
  border: 1px solid var(--color-input-border);
  color: var(--color-text);
}

.searchBar input::placeholder {
  color: var(--color-input-placeholder);
}

.searchBar input:focus {
  border: 1px solid var(--color-input-border-focus);
}

.nonClickable {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
