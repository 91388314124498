.container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  padding: 20px;
}

.select {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 225px;
  margin-right: 30px;
  font-size: 0.9em;
}

.label {
  text-align: left;
  color: var(--color-text);
  opacity: 1;
  font-weight: bold;
  font-size: 16px;
}

.search {
  display: flex;
  flex-direction: column;
  width: 250px;
  font-size: 0.85em;
  margin-top: 1.75rem;
}

.search input {
  width: 100%;
}

.search div {
  height: 100%;
  justify-content: space-between;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 30px;
  width: 130px;
}

.updateBtn {
  font-size: 16px;
  border: transparent;
  border-radius: 0.5em;
  background-color: var(--color-button-accent);

  width: 130px;
  height: 30px;
}

.search input {
  width: 100%;
  background-color: var(--color-dropdown-bg);
  border: 1px solid var(--color-input-border);
  color: var(--color-text);
}

.search input::placeholder {
  color: var(--color-input-placeholder);
}

.search input:focus {
  border: 1px solid var(--color-input-border-focus);
}

@media (min-width: 1270px) and (max-width: 1400px) {
  .select {
    width: 130px;
    margin-right: 20px;
    font-size: 0.9em;
  }

  .search {
    width: 200px;
    font-size: 0.8em;
  }
}

@media (min-width: 1197px) and (max-width: 1269.98px) {
  .label {
    font-size: 14px;
  }

  .select {
    width: 125px;
    margin-right: 15px;
    font-size: 13px;
  }

  .search {
    width: 150px;
    font-size: 12px;
  }

  .updateBtn {
    font-size: 14px;
  }

  .button {
    margin-left: 10px;
  }
}

@media (min-width: 1134px) and (max-width: 1196.98px) {
  .label {
    font-size: 14px;
  }

  .select {
    width: 115px;
    margin-right: 15px;
    font-size: 12px;
  }

  .search {
    width: 140px;
    font-size: 11px;
  }

  .updateBtn {
    font-size: 14px;
    height: 25px;
    width: 115px;
  }

  .button {
    margin-left: 20px;
    width: 115px;
  }
}

@media (max-width: 1133.98px) {
  .label {
    font-size: 13px;
  }

  .select {
    width: 110px;
    margin-right: 10px;
    font-size: 10.5px;
  }

  .search {
    width: 120px;
    font-size: 11px;
  }

  .updateBtn {
    font-size: 14px;
    height: 25px;
    width: 100px;
  }

  .button {
    margin-left: 10px;
    width: 100px;
  }
}
