.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 3px solid var(--color-border);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.label {
  text-align: left;
  color: var(--color-text);
  opacity: 1;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 1em;
}

.select {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 140px;
  max-width: 220px;
  margin-right: 30px;
  flex-grow: 1;
  font-size: 15px;
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar) var(--color-card-bg);
}

.select > div > button:disabled {
  color: var(--color-button-disabled);
  cursor: default;
}

.select::-webkit-scrollbar-track {
  background-color: var(--color-card-bg);
}
.select::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar);
}

.search {
  display: flex;
  flex-direction: column;
  min-width: 140px;
  max-width: 350px;
  font-size: 15px;
  flex-grow: 1;
}

.select input {
  background-color: var(--color-input-bg) !important;
}

.search input {
  /* width: 100%; */
  background-color: var(--color-dropdown-bg);
  border: 1px solid var(--color-input-border);
  color: var(--color-text);
  margin-top: 18px;
  height: 30px !important;
}

.search input::placeholder {
  color: var(--color-input-placeholder);
}

.search input:focus {
  border: 1px solid var(--color-input-border-focus);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 30px;
  width: 130px;
}

.button {
  font-size: 15px;
  border: transparent;
  border-radius: 0.5em;
  background-color: var(--color-button-default);
  padding: 0.5em 1em;
  height: 30px;
  color: var(--color-a) !important;
}
.button:hover {
  background-color: var(--color-button-default) !important;
}

.clearButton {
  margin: 24px 0 0;
  max-width: 120px;
  background-color: var(--color-button-clear);

  border: none;
  border-radius: 5px;
  color: var(--color-a);
  padding: 4px 8px;
  cursor: pointer;
  text-align: center;

  font-size: 14px !important;
}

/* .clearButton:hover {
  background-color: var(--color-button-clear-hover);
} */

.clearButton:active {
  background-color: var(--color-button-clear-active);
}

@media (min-width: 1540px) and (max-width: 1700px) {
  .label {
    font-size: 15px;
  }

  .select {
    margin-right: 15px;
    font-size: 14px;
    min-width: 130px;
  }

  .search {
    margin-right: 15px;
    font-size: 14px;

    min-width: 150px;
  }

  .search input {
    margin-top: 18px;
  }

  .buttonContainer {
    margin-left: 0px;
    width: 120px;
  }
}

@media (max-width: 1539px) {
  .label {
    font-size: 15px;
  }

  .select {
    margin-right: 15px;
    font-size: 14px;
    min-width: 110px;
  }

  .search {
    font-size: 14px;
    min-width: 130px;
  }

  .search input {
    height: 28px !important;
  }

  .buttonContainer {
    margin-left: 10px;
    width: 130px;
  }

  .button {
    height: 28px;
  }
}

@media (min-width: 1280px) and (max-width: 1349px) {
  .label {
    font-size: 15px;
  }

  .select {
    margin-right: 10px;
    font-size: 14px;
    min-width: 100px;
  }

  .search {
    font-size: 14px;
    min-width: 120px;
  }

  .search input {
    height: 28px !important;
  }

  .buttonContainer {
    margin-left: 10px;
    width: 130px;
  }

  .button {
    height: 28px;
  }
}

@media (max-width: 1279px) {
  .label {
    font-size: 15px;
  }

  .select {
    margin-right: 10px;
    font-size: 14px;
    min-width: 100px;
  }

  .search {
    font-size: 14px;
    min-width: 120px;
  }

  .search input {
    height: 28px !important;
  }

  .buttonContainer {
    margin-left: 10px;
    width: 130px;
  }

  .button {
    height: 28px;
  }
}
