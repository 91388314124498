.fontsize {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}

.ticketGroup {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}
