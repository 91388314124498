
.eventListItem {
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1em;
  
  cursor: pointer;

  padding: 0.3em 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}


/*
.details {
  margin-top: 1.5em;
}


.iboListItem:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}


.noSelection {
  margin-top: 2.5em;
}
*/
