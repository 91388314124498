.container {
  position: relative;
  height: 36px;
}

.select {
  appearance: none;
  background-color: var(--color-input-bg);
  font-size: 1em;
  
  border: 3px solid var(--color-input-border);
  outline: none;
  color: var(--color-input-text);

  height: 36px;
  width: 100%;
  border-radius: 8px;
  padding: 0 0.4em;
  padding-right: 36px;
}

.select:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: var(--color-input-bg);
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
