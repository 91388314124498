.flex {
  display: flex;
  align-items: center;
  min-width: 575px;
  font-size: 14px;
}

.flex * {
  line-height: 1.4;
}

.flex > div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% * (230 / 575));
  padding-right: 50px;
}

.flex > div:first-child p {
  padding: 0 1px;
  width: fit-content;
}

.flex > p:nth-child(2) {
  padding-right: 10px;
  width: calc(100% * (135 / 575));
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bold {
  font-weight: 600;
  text-transform: uppercase;
}

.location {
  text-transform: uppercase;
}

.date {
  display: flex;
  width: calc(100% * (110 / 575));
  gap: 8px;
}

.button-container {
  flex-shrink: 1;
  margin-left: auto;
}

.button {
  background-color: var(--color-button-save);
  color: var(--color-button-text);
  border-radius: 6px;
  padding: 2px 8px;
  border: 2px solid var(--color-button-save-border);
  cursor: pointer;
  margin-right: 6px;
}

.button:hover {
  background-color: var(--color-button-save-hover);
}

.disabledButton {
  background-color: var(--color-button-bg-disabled);
  color: var(--color-button-disabled);
  border: 2px solid var(--color-button-disabled);
  border-radius: 6px;
  padding: 2px 8px;
  cursor: not-allowed;
  margin-right: 6px;
}
