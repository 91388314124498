.container {
  width: 100%;
  padding-bottom: 100px;
}

/* .headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem 0rem 0.5rem 0.5rem;
} */

.pageHeader {
  width: 100%;
  padding: 1.5rem 0rem 1.5rem 0.5rem;
  font-size: 1.2em;
  font-family: var(--font-family);
  position: sticky;
  top: -3%;
  width: 100%;
  z-index: 200;
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--color-border);
  color: var(--color-text);
}

.title {
  font-size: 1.2em;
}

.saveButton {
  font-size: 16px;
  width: 65px;
  height: 32px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 5px;
}

.infoCard {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.saveButtonBottom {
  font-size: 16px;
  width: 65px;
  height: 32px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 5px;
  float: right;
}

.saveButton {
  font-size: 16px;
  width: 65px;
  height: 32px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 5px;
}
