.cardContainer {
  background: var(--color-card-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-border);
  width: 100%;
  padding: 1em 1em 1.5em 1em;
  color: #000000;
  font-size: 12px;
  
}

.cardTitle {
  font-weight: bold;
  padding-bottom: 0;
}

.cardTitle span {
  margin-bottom: 0px;
}

.cardHR {
  border: 0.5px solid #afafaf;
  margin-top: 10px;
  margin-bottom: 16px;
  width: 100%;
}

.cardLightButton {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  border: 1px solid #2c3847;
  border-radius: 5px;
  font: normal normal bold 8px/10px;
  letter-spacing: 0px;
  color: #2a3847;
  text-transform: capitalize;
}

.cardDarkButton {
  background: #2a3847 0% 0% no-repeat padding-box;
  border: 1px solid #2c3847;
  border-radius: 5px;
  font: normal normal bold 8px/10px;
  font-family: var(--font-family-mono);
  letter-spacing: 0px;
  color: #fbfcfd;
  text-transform: capitalize;
}

.cardDoubleRow div {
  float: left;
  width: 68%;
}

.cardDoubleRow div:nth-child(2) {
  width: 8%;
}

.cardDoubleRow div span:nth-child(1) {
  width: 15.5%;
}
