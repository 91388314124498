.paginationContainer {
  display: inline-flex;
  border: 1px solid var(--color-border);

  border-radius: 8px;
  background-color: var(--color-card-bg);
}

.previousButton,
.pageButton,
.nextButton {
  background-color: var(--color-card-bg);

  border-bottom: none;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pageButton {
  border-right: 1px solid var(--color-border);
}

.page1 {
  border-left: 1px solid var(--color-border);
}

.previousButton {
  background-color: var(--color-card-bg);

  border-radius: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.pageButton {
  border-right: 1px solid var(--color-border);
  border-radius: 0px;
}

.previousButton:hover,
.pageButton:hover,
.nextButton:hover {
  background-color: var(--color-a);
  cursor: pointer;
}

.previousButton:disabled:hover,
.pageButton:disabled:hover,
.nextButton:disabled:hover {
  background-color: var(--color-a);
}

.active,
.active:hover {
  background-color: var(--color-text-faded);
  color: var(--color-button-default) !important;
}
