.container {
  width: 165px;
  padding-top: 25.6px;
  padding-bottom: 25.6px;
  border-radius: 12px;
  border: 3px solid var(--color-border);
  background-color: var(--color-bg);
  color: var(--color-text);
  float: right;
}

.button {
  border: none;
  border-radius: 6px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  width: 130px;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.content {
  width: 160px;
  text-align: center;
  font-weight: bold;
  margin: auto;
}

@media (min-width: 1350px) and (max-width: 1380px) {
  .content {
    width: 120px;
  }

  .container {
    width: 130px;
  }

  .button {
    width: 100px;
    font-size: 13px;
  }
}

@media (min-width: 1280px) and (max-width: 1349px) {
  .content {
    width: 120px;
  }

  .container {
    width: 130px;
  }

  .button {
    width: 100px;
    font-size: 13px;
  }
}

@media (max-width: 1279px) {
  .content {
    width: 100px;
  }

  .container {
    width: 115px;
  }

  .button {
    width: 100px;
    font-size: 13px;
  }
}
