.container {
  padding: 20px;
  border-radius: 10px;
  border: 3px solid var(--color-border);
  background-color: var(--color-card-bg);

  width: 100%;
  height: auto;
}

/* Sorter */
.sortGrid {
  background-color: var(--color-card-header-bg);
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  display: grid;
  grid-template-columns: 15% 37.5% 22% 15% auto;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}

.venueList {
  /* max-height: calc(70vh - 95px); */
  height: calc(100vh - 368px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar) var(--color-card-bg);
}

.venueList::-webkit-scrollbar-track {
  background-color: var(--color-card-bg);
}
.venueList::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar);
  border-radius: 100px;
}

.whiteBG {
  align-items: center;
  background-color: var(--color-card-bg);
}

.slightlyDarkerBackground {
  background-color: var(--color-card-list-bg);
}

.venues {
  margin-top: 50px;
}

.noVenueText {
  margin: 20px 0;
  font-size: 15px;
}
