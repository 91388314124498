.section {
  padding-bottom: 2em;
  width: 100%;
}

.section:last-child {
  padding-bottom: 0.5em;
}

.section > header {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.row:not(:last-child) {
  margin-bottom: 1em;
}

.column {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.column:not(:last-child) {
  margin-right: 1em;
}

.labelGroup:not(:last-child) {
  margin-bottom: 1em;
}
