.container {
  width: 100%;
}

.modal {
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 0%);
  width: 600px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
}

.eventSavedModalText {
  letter-spacing: 0px;
  color: var(--color-text);
  margin-bottom: 16px;
  font-family: var(--font-family);
}

.buttonContainer {
  display: flex;
  float: right;
  padding: 1rem 0;
}

.eventDashboardButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  text-align: left;
  letter-spacing: 0px;
  color: var(--color-button-white-text);
  text-transform: uppercase;
  height: 32px;
  width: auto;
  border-radius: 8px !important;
  margin-right: 15px;
  font-size: 15px;
  padding: 15px !important;
  text-transform: uppercase;
}

.continuEditingButton {
  text-align: left;
  letter-spacing: 0px;
  text-transform: uppercase;
  height: 32px;
  width: auto;
  border-radius: 8px !important;
  margin-right: 15px;
  font-size: 15px;
  padding: 15px !important;
  text-transform: uppercase;
  margin-left: 15px;

  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
}
.eventDashboardButton:hover {
  background-color: var(--color-button-white-hover);
}

.continuEditingButton:hover {
}

.viewEventButton {
  text-align: left;
  letter-spacing: 0px;
  height: 32px;
  width: auto;
  border-radius: 8px !important;
  font-size: 15px;
  padding: 15px !important;
  text-transform: uppercase;

  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
}

.viewEventButton:hover {
  background-color: var(--color-button-white-hover);
}
