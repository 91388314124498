.container {
  padding: 20px;
  border-radius: 10px;
  border: 3px solid var(--color-border);
  background-color: var(--color-card-bg);

  width: 100%;
  height: auto;
}

/* Sorter */
.sortGrid {
  background-color: var(--color-card-header-bg);
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  display: grid;
  grid-template-columns: 12% 23% 10% 10% 10% 15% 15% auto;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}

.homesitesList {
  height: auto;
  max-height: calc(100vh - 390px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar) var(--color-card-bg);
}

.homesitesList::-webkit-scrollbar-track {
  background-color: var(--color-card-bg);
}
.homesitesList::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar);
  border-radius: 100px;
}

.whiteBG {
  align-items: center;
  background-color: var(--color-card-bg);
}

.slightlyDarkerBackground {
  background-color: var(--color-card-list-bg);
}

.homesites {
  margin-top: 50px;
}

.noHomesitesText {
  margin: 20px 0;
  font-size: 15px;
}

/* Homesite Row */
.homesiteContainer {
  color: var(--color-text);
  display: grid;
  grid-template-columns: 40% 40% 15%;
  align-items: center;
  width: 100%;
  font-weight: 300 !important;
  height: 35px;
}

.gridItem {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noMargin {
  margin-left: 0px;
}

.button {
  display: flex;
  align-items: center;
  width: 50px;
  height: 25px;
  background-color: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  border-radius: 7px;
  color: var(--color-button-white-text);
  margin-left: auto;
  transform: translateY(-2px);
  font-size: 13px;
  font-weight: 600;
}

.button:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.button:active {
  background-color: var(--color-button-active);
}

@media (min-width: 1340px) and (max-width: 1540px) {
  .gridItem {
    max-width: 280px;
  }
}

@media (max-width: 1339px) {
  .gridItem {
    max-width: 200px;
  }
}
