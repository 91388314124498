.button {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  display: flex;
  align-items: center;
  padding: 0.25em;
  border-radius: 6px;
}

.children {
  color: var(--color-text);
  font-weight: bold;
}

.indicator {
  border: 2px solid var(--color-border);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 6px;
}

.icon {
  width: 16px;
  height: 16px;
  stroke: var(--color-border);
  stroke-width: 3;
}

.icon.reverse {
  transform: rotate(180deg);
}

/*----- Hover State -----*/

.button:hover {
  background-color: var(--color-border);
  cursor: pointer;
}

.button:hover .indicator {
  border-color: var(--color-text);
}

.button:hover .icon {
  stroke: var(--color-text);
}