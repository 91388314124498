.container {
  width: 100%;
  position: relative;
}

.hidden {
  display: none;
}

.dropdown {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  background-color: var(--color-input-bg);
  font-weight: 300;
  font-size: 1em;
  border: 1px solid var(--color-input-border);
  border-radius: 0.5em;
  z-index: 200;
  padding-top: 0.6em;
  padding-bottom: 0.6em;

  width: 100%;
  position: absolute;
  max-height: 200px;
}

.button-closed {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  font-weight: 700;
  font-size: 1em;
  border: 1px solid var(--color-button-default-border);
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 7px;
  padding-top: 2px;
  align-items: center;
}

.button-open {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: var(--color-bg);
  color: var(--color-input-text);
  font-weight: 700;
  font-size: 1em;
  border: 1px solid var(--color-input-border);
  border-radius: 9px;
  align-items: center;
  padding-top: 2px;
}

.dropdown hr {
  background-color: var(--color-border);
  border: none;
  height: 1px;
  margin: 0.25em 0.7em;
}

.items {
  padding: 0.25em 0.7em;
  cursor: pointer;
}

.items:hover {
  background-color: var(--color-dropdown-bg-hover);
}

.chevron {
  transform: translateY(-1px);
}
