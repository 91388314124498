.title {
  font-size: 16px;
  font-weight: bold;
}

.row {
  display: flex;
  flex-direction: row;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.visibleToCol span:nth-child(2) {
  /* font-size: 15.5px !important; */
  font-size: 14px;
}

.visibleAddButton {
  margin-left: 8px;
  font-size: 15px;
  margin-top: 5px;
}

.label {
  font-weight: bold;
  font-size: 15px;
}

.col,
.visibleToCol,
.tentative {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 0.7em;
}

.col > .label,
.visibleToCol > .label,
.tentative > .label {
  min-width: 170px;
}

.datePicker input,
.timePicker input {
  font-size: 13.5px;
  font-weight: normal;
  height: 23px;
  width: 120px;

  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.datePicker input:focus,
.timePicker input:focus {
  border: 2px solid var(--color-input-border-focus);
}

/* .datePicker {
  margin-right: 0.5em;
} */

.datePicker div {
  height: 22px !important;
  border-radius: 5px !important;
  font-size: 11px;
}

.datePicker div svg {
  height: 16px !important;
  width: 16px !important;
  padding: 0 !important;
  font-size: 14px;
}

.timePicker {
  margin-left: 10px;
  margin-right: 20px;
}

.timePicker div {
  height: 22px !important;
}

.timePicker div svg {
  font-size: 14px;
}

.timePicker input {
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
}

.timePicker button {
  font-size: 11.5px;
  font-weight: normal;
}

.timezones div {
  /* margin-left: 2em; */
  width: 60px;
  font-size: 12px;
  font-weight: normal;
}

.timezones div button {
  padding-left: 0.5em;
}

.visibleToSelect {
  font-size: 14px;
}

.visibleToSelect div {
  width: 11.1em;
}

.tentative {
  width: 64%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox div {
  display: flex;
}

.checkbox input {
  background-color: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-border);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  align-self: center;
}

.checkbox input:after {
  border: 1px solid var(--color-checkbox-border-active);
}

.checkbox:checked:after {
  background: var(--color-checkbox-border-active);
}

@media (max-width: 1280px) {
  .datePicker input,
  .timePicker input {
    font-size: 13px;
    width: 120px;
  }

  /* .timePicker {
    margin-left: -5px;
    margin-right: -10px;
  } */

  .label {
    font-size: 14px;
  }

  .visibleToSelect button {
    font-size: 13px !important;
  }
}

@media (max-width: 1099.98px) {
  .col > .label,
  .visibleToCol > .label,
  .tentative > .label {
    min-width: 160px;
  }

  .label {
    font-size: 14px;
  }

  .timePicker {
    margin-left: 5px;
    margin-right: 15px;
  }
}
