.hostCardInteractiveProfilePhoto {
  margin-right: 40px;
  width: 150px;
  height: 150px;
  border: 1px solid var(--color-surface-yellow);
  border-radius: 8px;
  float: right;
}

.hostCardInteractiveEditButton {
  background: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  border-radius: 6px;
  color: var(--color-button-white-text);
  float: right;
  height: 26px;
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: bold;
  /* font-size: 11px; */
}

.hostCardInteractiveEditButton:hover {
  cursor: pointer;
}

.hostCardInteractiveEditButton:active {
  filter: invert(10%);
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

/*  */
.label {
  font-weight: bold;
}

.name {
  margin-bottom: 16px;
  color: var(--color-link) !important;
}

.name:hover {
  color: var(--color-link-hover) !important;
}

.item {
  display: grid;
  grid-template-columns: 190px auto;
}
