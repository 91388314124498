.container {
  position: relative;
}

.text {
  position: absolute;
  line-height: 20px;
  margin: auto;
  margin-left: 15px;
  user-select: none;
  white-space: nowrap;
}

.plusSymbolContainer {
  border-radius: 2px;
  height: 20px;
  width: 20px;

  display: inline-block;
}

.plusSymbolContainer:hover {
  cursor: pointer;
}

.horizontalCross {
  width: 12px;
  height: 2px;
  position: absolute;
  z-index: 100;
  background-color: var(--color-button-text);
  transform: translate(4px, 9px);
}

.verticalCross {
  width: 2px;
  height: 12px;
  position: absolute;
  z-index: 100;
  background-color: var(--color-button-text);

  transform: translate(9px, 4px);
}
