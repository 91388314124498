.container {
  --tab-height: 35px;
  --tab-bleed: 10px;

  position: relative;
  padding-top: var(--tab-height);
  margin-bottom: 1em;
}

.tabContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--tab-height) + var(--tab-bleed));
}

.tabScroll {
  position: absolute;
  top: 0;
  left: 0;
  width: min-content;
  display: flex;
  flex-flow: row nowrap;
}

.tab {
  position: relative;
  z-index: 0;
}

/* Collapse borders between adjacent tabs */
.tab:not(:first-child) {
  margin-left: -3px;
}

.tab.selected {
  z-index: 2;
}

.tab.selected .tabTitle {
  color: var(--color-text);
}

.tab.selected .tabButton {
  border-color: var(--color-border);
}

.tabButton {
  position: relative;
  appearance: none;
  outline: none;
  border: 3px solid var(--color-border-faded);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: var(--color-card-bg);
  padding: 0 0.55em;
  font-size: 1em;
  
  height: calc(var(--tab-height) + var(--tab-bleed));
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.tabBadge {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-surface-blue);
  margin-top: calc(var(--tab-height) / 2 - 5px);
  margin-right: 0.4em;
}

.tabTitle {
  height: calc(var(--tab-height) - 3px);
  display: flex;
  align-items: center;
  color: var(--color-text-gray);
  font-weight: bold;
  white-space: nowrap;
}

.tabMask {
  height: calc(var(--tab-bleed) - 3px);
  background-color: var(--color-card-bg);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.tab:first-child .tabMask {
  left: 3px;
}

.content {
  z-index: 1;
  position: relative;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  background-color: var(--color-card-bg);
  padding: 1em;
}

.noSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 1em;
}

.overflow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: var(--tab-height);
  overflow: visible;
  display: flex;
  align-items: center;
}

.overflowButton {
  appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 32px;
  padding: 0 0.5em;
  color: var(--color-text-gray);
}

.overflowButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.overflowIcon {
  width: 22px;
  height: 22px;
  margin-left: 2px;
}

.overflowIcon > svg {
  width: 100%;
  height: 100%;
  stroke-width: 2;
}

.overflowMenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 900000000000;
  background-color: var(--color-card-bg);
  padding: 0.5em 0.75em;
  border: 3px solid var(--color-border);
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
}

.overflowItem {
  appearance: none;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1em;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  color: var(--color-text-gray);
}

.overflowItem:not(:last-child) {
  margin-bottom: 0.5em;
}

.overflowItem.active {
  color: var(--color-text);
}

.changedStyle {
  height: 5rem;
  background-color: aqua;
}

.styledTabContainer {
  width: 50%;
  margin: auto;
}

/* Tabs Overflowing */
@media (min-width: 1400px) and (max-width: 1455.98px) {
  .tabButton {
    padding: 0 0.5em;
    font-size: 0.95em;
  }

  .overflow {
    font-size: 0.95em;
  }

  .overflowButton {
    padding: 0 0.5em;
  }
}

@media (min-width: 1320px) and (max-width: 1399.98px) {
  .tabButton {
    padding: 0 0.47em;
    font-size: 0.9em;
  }

  .overflow {
    font-size: 0.9em;
  }

  .overflowButton {
    padding: 0 0.47em;
  }
}

@media (min-width: 1265px) and (max-width: 1319.98px) {
  .tabButton {
    padding: 0 0.45em;
    font-size: 0.85em;
  }

  .overflow {
    font-size: 0.85em;
  }

  .overflowButton {
    padding: 0 0.45em;
  }
}

@media (min-width: 1200px) and (max-width: 1264.98px) {
  .tabButton {
    padding: 0 0.4em;
    font-size: 0.8em;
  }

  .overflow {
    font-size: 0.8em;
  }

  .overflowButton {
    padding: 0 0.4em;
  }
}

@media (min-width: 1130px) and (max-width: 1199.98px) {
  .tabButton {
    padding: 0 0.35em;
    font-size: 0.75em;
  }

  .overflow {
    font-size: 0.75em;
  }

  .overflowButton {
    padding: 0 0.35em;
  }
}

@media (min-width: 1075px) and (max-width: 1129.98px) {
  .tabButton {
    padding: 0 0.32em;
    font-size: 0.7em;
  }

  .overflow {
    font-size: 0.7em;
  }

  .overflowButton {
    padding: 0 0.32em;
  }
}

@media (max-width: 1074.98px) {
  .tabButton {
    padding: 0 0.3em;
    font-size: 0.67em;
  }

  .overflow {
    font-size: 0.75em;
  }

  .overflowButton {
    padding: 0 0.7em;
  }
}
