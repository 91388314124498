.imagePickerContainer {
  display: flex;
  flex-direction: column;
}

.imagePickerLabel {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-10) / var(--unnamed-line-spacing-12);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal bold 10px/12px;
  letter-spacing: 0px;
  color: var(--color-text);
  opacity: 1;
  margin-top: 10px;
  font-size: 15px;
}

.imagePickerUploadButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-top: 12px;
}
.imagePickerUploadButtonSmall {
  width: 24px !important;
  height: 26px !important;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePickerPlus {
  width: 24px;
  height: 24px;
  color: var(--color-button-white-text);
  stroke-width: 5px;
  display: block;
  margin: auto;
}
.imagePickerPlusSmall {
  min-width: 7.58px !important;
  min-height: 7.58px !important;
  max-width: 7.58px !important;
  max-height: 7.58px !important;
  margin: auto;
}

.imageName {
  font-size: 13px;
  margin-top: 10px;
  color: var(--color-link);
}

.adaIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.ticketIcon {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.icon {
  width: 110px;
  height: 110px;
  cursor: pointer;
  margin-top: 56px;
  margin-bottom: 36px;
}
