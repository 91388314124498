.container {
  color: var(--color-text);
  border: 1px solid var(--color-border);
  border-radius: 6px;
  font-size: 15px;
  padding: 1em;
  width: 650px;
}

.grid {
  display: grid;
  grid-template-columns: 20% 50% auto;
  grid-gap: 20px;

  width: 100%;
}

.left {
  font-size: 15px;
  font-weight: bold;
}

.image {
  margin: auto;
}

.imageIcon {
  border: 1px solid var(--color-surface-yellow);
  border-radius: 6px;

  /* width: 150px;
  height: 150px; */

  width: 120px;
  height: 120px;

  margin-left: auto;
  margin-right: 12px;
  margin-top: 2px;

  background: none;
  z-index: 100;
}

.imageIconCircle {
  border-radius: 50%;
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-border);
  background: none;
  /* width: 50px;
  height: 50px; */

  width: 30px;
  height: 30px;
  margin: auto;
  transform: translateY(35px);
}

.imageIconSquare {
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-border);
  border-bottom: none;
  background: none;
  /* width: 90px;
  height: 50px; */

  width: 70px;
  height: 30px;
  margin: auto;

  transform: translateY(49px);
}

.item {
  height: 20px;
  margin-bottom: 12px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1440px) {
  .container {
    min-width: 460px;
  }

  .left {
    font-size: 14px;
  }
}
