*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  --font-family: "Open Sans", "IBM Plex Sans", "Helvetica Neue", sans-serif;
  --font-family-mono: "IBM Plex Mono", "Menlo", "Consolas", monospace;

  --ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);

  /* Sizing & Responsiveness */

  --break-md: 600px;
  --break-lg: 900px;
  --break-xl: 1100px;

  --header-height: 58px;
  --max-content-width: 700px;

  /* Colors */

  --color-a: #ffffff;
  --color-b: #000000;
  --color-c: #243746;
  --color-d: #1a1d23;
  --color-e: #91b6bb;
  --color-f: #4b4e52;
  --color-g: #999999;
  --color-h: #ff0000;
  --color-i: #39b54a;
  --color-j: #d3b21b;
  --color-k: #cc3300;
  --color-l: #ea6217;
  --color-m: #f7931e;
  --color-n: #0087d4;
  --color-o: #008797;
  --color-p: #00c4d3;
  --color-q: #006837;
  --color-r: #20303d;
  --color-s: #cecece;
  --color-t: #f4c579;
  --color-u: #afafaf;

  --color-text-accent: #3f7a81;
  --color-icon-accent: #75a7ad;
  --color-bg-accent: #91b6bb;

  --color-text-gray: #8b9394;
  --color-text-blue: #586ada;
  --color-text-cyan: #067ab8;
  --color-text-green: #09815c;
  --color-text-yellow: #bb5506;
  --color-text-orange: #c44c35;
  --color-text-red: #cf2225;
  --color-text-purple: #a44fb5;
  --color-text-violet: #825fc3;

  --color-icon-gray: #8993a2;
  --color-icon-blue: #678ef1;
  --color-icon-cyan: #3a97d4;
  --color-icon-green: #1ba672;
  --color-icon-yellow: #d97919;
  --color-icon-orange: #e56f49;
  --color-icon-red: #cf2225;
  --color-icon-purple: #c96dd0;
  --color-icon-violet: #9e85db;

  --color-surface-gray: #8993a2;
  --color-surface-blue: #5f7dc9;
  --color-surface-cyan: #008797;
  --color-surface-green: #058d42;
  --color-surface-yellow: #fdc36c;
  --color-surface-orange: #e56f49;
  --color-surface-red: #cf2225;
  --color-surface-purple: #c96dd0;
  --color-surface-violet: #9e85db;
  --color-surface-darkgray: #415364;
  --color-surface-brown: #694919;

  --color-loader: #7c8996;

  /* Login Screen */

  --color-login-gradient-1: #91b6bb;
  --color-login-gradient-2: #243746;

  /* Navigation */

  --color-nav-main-bg: #253847;
  --color-nav-main-border: #556a7e;
  --nav-main-panel-border: transparent;

  --color-nav-sidebar-bg: #fff;
  --color-nav-sidebar-text: #000;
  --color-nav-sidebar-highlight: var(--color-surface-yellow);
  --color-nav-sidebar-text-highlight: var(--color-nav-sidebar-text);

  /* Checkbox */

  --color-checkbox-bg: var(--color-input-bg);
  --color-checkbox-border: var(--color-input-border);
  --color-checkbox-bg-active: var(--color-surface-yellow);
  --color-checkbox-border-active: var(--color-surface-yellow);
  --color-checkbox-icon: var(--color-input-bg);
  --color-checkbox-tag-bg: #638193;

  /* Radio Buttons */

  --color-radio-bg: var(--color-input-bg);
  --color-radio-border: var(--color-input-border);
  --color-radio-dot: transparent;
  --color-radio-bg-active: var(--color-surface-yellow);
  --color-radio-border-active: var(--color-surface-yellow);
  --color-radio-dot-active: var(--color-input-bg);

  /* Buttons */

  --color-button-text: #fff;

  --color-button-default: #243746;
  --color-button-default-hover: #30485a;
  --color-button-default-border: #010102;

  --color-button-accent: var(--color-e);
  --color-button-accent-border: var(--color-f);

  --color-button-black-bg: var(--color-b);

  --color-button-clear: #b3b3b3;
  --color-button-clear-hover: #9e9e9e;
  --color-button-clear-active: #7d7d7d;

  --color-button-delete: #cf2225;
  --color-button-delete-hover: #e6171a;
  --color-button-delete-border: #9e2a2a;

  --color-button-remove: #ff0000;

  --color-button-save: #058d42;
  --color-button-save-hover: #0d9c4d;
  --color-button-save-border: #0d723a;

  --color-button-white-bg: var(--color-a);
  --color-button-white-text: var(--color-c);
  --color-button-white-border: #243746;
  --color-button-white-hover: #ebeced;

  --color-button-text-hover: var(--color-b);

  --color-button-cart-order: #0433ff;

  --color-button-review: var(--color-n);
  --color-button-review-hover: #3593c9;
  --color-button-review-border: #0171b3;

  --color-button-hollow: none;
  --color-button-hollow-hover: #999999a9;
  --color-button-hollow-text: #000;

  --color-button-change-image: #223646;
  --color-button-change-image-hover: #122636;
  --color-button-change-image-text: var(--color-a);

  --color-button-disabled: #adb7c0;
  --color-button-bg-disabled: #d3d3d3;

  --color-button-yellow: var(--color-surface-yellow);
  --color-button-yellow-hover: #f3a737;
  --color-button-active: #202b36;

  /* Alert success message */
  --color-text-info: #0c5460;
  --color-background-color-info: #d1ecf1;
  --color-border-info: #bee5eb;

  /* Alert info message */
  --color-text-success: #155724;
  --color-background-color-success: #d4edda;
  --color-border-success: #c3e6cb;

  /* Error message */
  --color-text-error: #721c24;
  --color-background-color-error: #f8d7da;
  --color-border-error: #f5c6cb;

  /* Inputs */

  --color-input-text: var(--color-text);
  --color-input-placeholder: var(--color-text-gray);
  --color-input-bg: #fff;
  --color-input-border: var(--color-border);
  --color-input-border-focus: var(--color-surface-yellow);
  --color-input-border-invalid: var(--color-text-red);
  --color-input-outline-focus: var(--color-surface-yellow);
  --color-input-outline-invalid: var(--color-text-red);
  --color-input-shadow-focus: rgba(145, 182, 187, 0.5);
  --color-input-shadow-invalid: rgba(237, 95, 116, 0.5);
  --color-input-note-border-focus: #66afe9;
  --color-input-note-shadow-focus: rgba(102, 175, 233, 0.6);

  /* DatePicker */

  --color-datepicker-text: var(--color-text);
  --color-datepicker-selected-bg: #dedede;
  --color-datepicker-selected-text: #000;

  /* Cards */

  --color-card-bg: #fff;
  --color-card-text: #000;
  --color-card-header-bg: var(--color-form-bg);
  --color-card-list-bg: #dddddd33;

  /* Forms */

  --color-form-bg: #efefef;
  --color-form-input-bg: #fff;
  --color-form-bg-faded: #d3e2e4;

  /* Dropdown */
  --color-dropdown-bg: var(--color-form-bg);
  --color-dropdown-bg-hover: #80808033;
  --color-dropdown-options: var(--color-c);

  /* Checkbox group */

  --color-checkboxgroup-bg: #e4e4e4;

  /* Tables */

  --color-table-header-bg: var(--color-card-bg);
  --color-table-row-even-bg: #00000014;

  /* Links */

  --color-link: #1f3bd8;
  --color-link-hover: var(--color-n);
  --color-link-bg: #d7f3fd;
  --color-link-border: #88acb9;

  --color-link-bg-hover: #b7e4f3;
  --color-link-border-hover: #528ba1;

  --color-link-bg-visited: #f4d7ff;
  --color-link-border-visited: #956ea5;

  --color-link-bg-visited-hover: #eabbfd;
  --color-link-border-visited-hover: #9166a3;

  /* Page Colors */

  --color-bg: #fafbfc;

  --color-text: var(--color-b);
  --color-text-inverted: var(--color-a);
  --color-alt-text: var(--color-c);
  --color-text-accent: var(--color-e);
  --color-text-faded: #adb7c0;
  --color-text-darkened: #415363;

  --color-border: #c5cfd1;
  --color-border-faded: #d6dcdd;
  --color-divider: #707070;

  --color-shadow: rgba(0, 0, 0, 0.2);

  --color-banner: var(--color-r);
  --color-scrollbar: #888;

  --color-note-bg-text: #ffff00;
  --color-ticket-revoke-bg: #ff7070;

  --color-custom-table-bg-odd: #e6e7e8;
  --color-custom-table-bg-even: #fafbfc;

  --color-scan: #87ba80;
  --color-noselection-light-bg: #eee;
  --color-noselection-dark-bg: #333;

  --color-background-overlay: #f3efef80;
  --color-modal-fallback-overlay: rgba(0, 0, 0, 0.4);
  --color-modal-bg-overlay: rgba(0, 0, 0, 0.4);

  /* SVG */
  --icon-filter: brightness(1) invert(0);
}

/**
 * Automatically change to dark theme when the system is dark.
 */
@media (prefers-color-scheme: dark) {
  body {
    --color-input-bg: #000;
    --color-divider: #fff;
    --color-card-bg: #000;

    --color-loader: #808080;

    --color-icon-green: #17c06b;
    --color-text-green: #17c06b;

    /* --color-text-accent: #a1cbd1;
    --color-icon-accent: #a4ccd1;
    --color-bg-accent: #91b6bb; */

    --color-radio-bg: #ebaa48;

    --color-bg: #111;
    --color-text: #b9b1b1;
    --color-alt-text: #fff;
    --color-text-accent: #fff;
    --color-text-faded: #fff;
    --color-text-darkened: #fff;
    --color-text-gray: #808080;
    --color-text-inverted: #000;

    --color-border: #555;
    --color-border-faded: #444;

    /* --color-border: #4b5d5f; */
    /* --color-border-faded: #374142; */

    /* Login Screen */

    --color-login-gradient-1: #233d41;
    --color-login-gradient-2: #141e27;

    /* Navigation */

    --color-nav-main-bg: #17242e;
    --color-nav-main-border: #465c70;
    --nav-main-panel-border: 1px solid var(--color-nav-main-border);

    --color-nav-sidebar-bg: #111;
    --color-nav-sidebar-text: #fff;
    --color-nav-sidebar-highlight: var(--color-surface-yellow);
    --color-nav-sidebar-text-highlight: #000;

    /* Checkbox */

    --color-checkbox-border-active: var(--color-surface-yellow);
    --color-checkbox-tag-bg: var(--color-checkbox-bg-active);

    /* Checkbox group */

    --color-checkboxgroup-bg: #343434;

    /* Forms */

    --color-form-bg: #222;
    --color-form-input-bg: #222;
    --color-form-bg-faded: #404040;
    --color-bg-accent: #333;

    /* Tables */

    --color-table-header-bg: var(--color-card-bg);
    --color-table-row-even-bg: rgba(255, 255, 255, 0.2);

    /* Buttons */

    --color-button-text: #fff;

    --color-button-default: #495b69;
    --color-button-default-hover: #5a6c7a;
    --color-button-default-border: #31393f;
    --color-button-hollow-text: #fff;

    /* DatePicker */

    --color-datepicker-text: #fff;
    --color-datepicker-selected-bg: #555;
    --color-datepicker-selected-text: #fff;

    /* Links */

    --color-link-bg: #234350;
    --color-link-border: #396a7e;

    --color-link-bg-hover: #315868;
    --color-link-border-hover: #528ba1;

    --color-link-bg-visited: #53345f;
    --color-link-border-visited: #7e4f91;

    --color-link-bg-visited-hover: #6d467e;
    --color-link-border-visited-hover: #9166a3;

    /* SVG */
    --icon-filter: brightness(0) invert(1);
    --icon-filter-invert: brightness(1) invert(0);
  }
}
