.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  /* Ensure it's above other content */

  background-color: var(--color-modal-fallback-overlay);
  background-color: var(--color-modal-bg-overlay);
}

.specialNeedsModal {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  max-width: 1000px;
  margin: 1em;
  box-shadow: 0 4px 6px var(--color-shadow);
}

.specialNeedsTitle {
  font-size: 2em;
  color: var(--color-text-accent);
  padding-bottom: 20px;
}

.specialNeedsDesc {
  font-style: italic;
  font-weight: 600;
  text-align: left;
}

.specialNeedsOptions {
  display: grid;
  grid-template-columns: 2em 1fr;
  margin: 1em 0;
  grid-gap: 0.5em;
}

.specialNeedsOptions input[type="checkbox"] {
  height: 1.2em;
  width: 1.2em;
  grid-column: 1;
  margin: auto auto;
  cursor: pointer;
}

.specialNeedsOptions label {
  grid-column: 2;
}

.specialNeedsOr {
  text-align: right;
}

.specialNeedsOptions > hr {
  grid-column: 1 / 3;
  border-color: var(--color-border);
  margin: 0.5em;
}

.containerSpecialNeedsButtons {
  display: flex;
  justify-content: center;
  gap: 0.5em;
}
/* SpecialNeedsModal.module.css */

.successMessage {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: var(--color-background-color-success);
  /* Light green background */
  color: var(--color-text-success);
  /* Dark green text */
  text-align: center;
}

.errorMessage {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: var(--color-background-color-error);
  /* Light red background */
  color: var(--color-text-error);
  /* Dark red text */
  text-align: center;
}

.onSaveButton:hover {
  background-color: var(--color-button-default-hover);
}

.onCancelButton:hover {
  background-color: var(--color-button-white-hover);
  color: var(--color-b);
}

.closeButton {
  display: flex;
  justify-content: center;
  margin: auto;
}
