.container {
  padding: 20px;
  border-radius: 10px;
  border: 3px solid var(--color-border);
  background-color: var(--color-card-bg);
  height: auto;
  position: relative;
}

.sortItem {
  width: 50%;
  /* background-color: aqua; */
}

.whiteBG {
  background-color: var(--color-card-bg);
}

.slightlyDarkerBackground {
  background-color: var(--color-card-list-bg);
  /* margin-bottom: 10px; */
}

.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  margin-top: 20px;
}

.venues {
  margin-top: 50px;
}

@media (max-width: 1540px) {
  .container {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: auto;
    max-height: 480px;
  }
}

.flexContainer {
  background-color: var(--color-card-header-bg);
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.flexItem {
  flex: 1;
}

.nameHeader {
  flex: 0 0 37.5%;
}

.locationHeader {
  flex: 0 0 36.5%;
}

.startsOHeader {
  flex: 0 0 25%;
}
.listContainer {
  max-height: 525px !important;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar) var(--color-card-bg);
}
