.container {
  width: 100%;
  display: grid;
  grid-template-columns: 12% 15% 16% 17% 14% 19% auto;

  padding-left: 14px;
  line-height: 35px;
}

.item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 1px;
  font-size: 1em;
  font-weight: 100 !important;
  vertical-align: middle;
}

.orderId {
  font-weight: bold;
  text-decoration: underline var(--color-link-border);
  cursor: pointer;
}

.orderId a {
  color: var(--color-link);
}

.orderId a:hover {
  color: var(--color-link-hover);
}

.removeBtn {
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  background: var(--color-button-white-bg);
  border-radius: 5px;
  padding: 3px 7px;
  line-height: calc(1em + 2px);
  height: auto;
  margin: 5px 0;
  font-weight: bold;
  transform: none;
}

.removeBtn:hover {
  background-color: var(--color-button-white-hover);
}

/* .orderId > a:link,
.orderId > a:active,
.orderId > a:visited {
  color: #2a3847;
} */

@media (min-width: 1620px) and (max-width: 1760px) {
  .container {
    display: grid;
    grid-template-columns: 12% 16% 16% 16% 15% 16% auto;
  }

  .item {
    max-width: 160px;
  }
}

@media (min-width: 1541px) and (max-width: 1619px) {
  .container {
    display: grid;
    grid-template-columns: 12% 15% 16% 16% 16% 16% auto;
  }

  .item {
    max-width: 135px;
  }
}

@media (min-width: 1390px) and (max-width: 1540px) {
  .container {
    display: grid;
    grid-template-columns: 13% 15% 16% 16% 16% 14.5% auto;
  }

  .item,
  .orderId {
    max-width: 120px;
    font-size: 0.92em;
  }
}

@media (min-width: 1280px) and (max-width: 1389.98px) {
  .container {
    display: grid;
    grid-template-columns: 13% 14% 15% 16% 17% 15.5% auto;
  }

  .item {
    max-width: 90px;
    font-size: 0.85em;
  }

  .orderId {
    font-size: 0.85em;
    min-width: 95px;
  }
}

@media (min-width: 1201px) and (max-width: 1279.98px) {
  .container {
    display: grid;
    grid-template-columns: 14% 13% 13% 16% 19% 14% auto;
  }

  .item {
    max-width: 80px;
    font-size: 0.85em;
  }

  .orderId {
    font-size: 0.84em;
    min-width: 95px;
  }
}

@media (min-width: 1201px) and (max-width: 1279.98px) {
  .container {
    display: grid;
    grid-template-columns: 14% 13% 13% 16% 19% 14% auto;
  }

  .item {
    max-width: 80px;
    font-size: 0.85em;
  }

  .orderId {
    font-size: 0.84em;
    min-width: 95px;
  }
}

@media (max-width: 1200px) {
  .container {
    display: grid;
    grid-template-columns: 15% 11% 11% 17% 20% 13% auto;
  }

  .item {
    max-width: 65px;
    font-size: 0.84em;
  }

  .orderId {
    font-size: 0.84em;
    min-width: 95px;
  }
}
