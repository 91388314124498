.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
}

.container:hover {
  cursor: pointer;
}

.imgContainer {
  justify-content: center;
}

.image {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.text {
  font-size: 14px;
  margin-left: 5px;
  text-decoration: underline;
  color: var(--color-text);
}
