.container {
  width: 100%;
  height: auto;
}

.cardHeader {
  font-weight: bold;
}

.button {
  float: right;
  background-color: var(--background-color);
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  height: 27px;
  width: 50px;
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
  transform: translateY(-25px);
}

.button:hover {
  cursor: pointer;
}

.button:active {
  filter: invert(10%);
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.venueNameDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 15px;
}

@media (max-width: 1400px) {
  .venueNameDiv {
    flex-direction: column;
  }

  .venueMapDiv {
    margin-top: 20px;
  }
}
