.inputWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.searchBar {
  border: 1px solid var(--outline);
  background: var(--color-input-bg);
  border-radius: 0.5em;
  padding: 0.3em 0.1em 0.4em 0.8em;
  color: var(--color-input-text);
  width: 100%;
  font-size: inherit;
  font-weight: 100 !important;
}

.searchBar::placeholder {
  color: var(--color-input-placeholder);
  font-weight: 100 !important;
}

.searchBar:focus {
  outline: none;
}

.clearButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-15%);
  background: none;
  border: none;
  color: var(--color-button-clear);
  font-size: 1.2em;
  cursor: pointer;
  padding: 0;
}

.clearButton:hover {
  color: var(--color-button-clear-hover);
}
