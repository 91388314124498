.container {
  width: 100%;
  font-size: 15px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.textAreaLabel {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .textArea {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  border: 1px solid #afafaf;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 5px;
  padding-left: 10px;
  font-size: 11px;

  resize: none;
}

textarea::placeholder {
  color: #afafaf;
}

textarea:focus {
  outline: none;
} */

.grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: bold;
  font-size: 15px;
}

/* .hostCard {
  width: 50%;
} */

.styledBtn {
  background: var(--color-button-white-bg);
  color: var(--color-button-white-text);
  border-color: var(--color-button-white-border);
}

.editButton {
  float: right;
  background: var(--color-button-white-bg);
  border: 1px solid var(--color-button-white-border);
  border-radius: 6px !important;
  /* font-size: 11px !important; */
  font-weight: bold !important;
  color: var(--color-button-white-text);
  width: 64px !important;
  /* height: 26px !important; */
  height: auto;
  font-family: var(--font-family-mono);
  margin-top: 1px;
}

.editButton:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.add {
  display: flex;
  justify-content: space-between;
}

.addBtn {
  justify-self: start;
  width: 50%;
}

.removeButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  border-radius: 5px;
  color: var(--color-button-white-text) !important;
  height: 24px;
  margin-right: 5px;
}

.removeButton:hover {
  cursor: pointer;
  background-color: var(--color-button-white-hover);
}

.marginTop {
  margin: 20px;
}
