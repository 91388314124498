.container {
  display: flex;
  height: 100%;
  /* align-items: flex-end; */
  /* display: grid; */
  /* grid-template-columns: auto auto; */
 /* padding-right: 10px; */
  position: relative;
}

.container:hover {
  cursor: pointer;
}

.text {
  white-space: nowrap;
  font-weight: 600;
  width: fit-content;
  padding-right: 10px;
}

.arrow {
  height: 18px;
  width: auto;
  /* margin: auto; */
  transform: translateY(-3px);
}

.flip {
  transform: rotate(180deg) translateY(-0px);
}
