.container {
  font-size: 15px;
}

.fontSize {
  letter-spacing: 0px;
  color: var(--color-text);
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.primaryInputItem {
  display: grid;
  grid-template-columns: 82% 10% auto;

  align-items: center;
  font-size: 15px;
}

.primaryInputItem > div {
  grid-template-columns: 20% 80%;
}

.secondaryInputItem {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 13.5% 66.5% 5% 5%;
  align-items: center;
  font-size: 15px;
}

.secondaryInputItem div span {
}

.secondaryInputItem > div {
  grid-template-columns: 15% 80%;
  font-size: 15px;
}

.removeButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-button-white-border);
  color: var(--color-button-white-text);
  border-radius: 8px;
  width: 5em;
}

.removeButton:hover {
  background-color: var(--color-button-white-hover);
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .primaryInputItem {
    display: grid;
    grid-template-columns: 82% 8% auto;
  }
}

@media (max-width: 1280px) {
  .container {
    font-size: 14px;
  }

  .primaryInputItem {
    display: grid;
    grid-template-columns: 80% 6% auto;
    font-size: 14px;
  }

  .primaryInputItem > div {
    grid-template-columns: 25% 75%;
  }

  .secondaryInputItem {
    font-size: 14px;
  }

  .secondaryInputItem > div {
    grid-template-columns: 25% 68%;
  }
}
