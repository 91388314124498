.container {
  width: 100%;
  height: auto;
  font-size: 15px;
}

.cardHeader {
  font-weight: bold;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
  border: 0.5px solid var(--color-border);
}

.addBtn {
  transform: translateY(-3px);
  float: right;
}
