.dateTimeSet {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.rowContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.labelContainer {
  flex: 0 0 180px;
  font-size: 15px;

  font-weight: bold;
}

.suffixLabelContainer {
  /* margin-left: 10px; */
  font-size: 15px;
  font-weight: 500;
  min-width: 90px;
}

.labelAndPickerContainer {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
  margin-right: -50px !important;
}

.timePickerContainer input {
  margin-left: -25px !important;
}

.datePickerContainer input,
.timePickerContainer input {
  height: 23px;
  width: 120px;
  font-size: 13.5px;
  background: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
}

.datePickerContainer button {
  font-size: 11px;
  font-weight: normal;
}

.dateText {
  /* margin-right: 5px; */
  min-width: 100px !important;
  max-width: 300px;
  font-size: 14.5px;
}

.timezoneText {
  font-size: 14.5px;
  min-width: 300px;
  margin-left: -10px;
}

.timezoneEdit {
  font-size: 14.5px;
  min-width: 300px;
  /* margin-left: -10px; */
}

.cardHeader {
  font-size: 16px;
  font-weight: bold;
  /* padding-bottom: 10px; */
}

.dateTimeSet:first-child {
  margin-top: 15px;
}

.timezone {
  align-items: center;
  font-size: 12px !important;
  margin-left: -5px;
}

.timezone div {
  font-weight: normal;
}

.timezone div button {
  width: 165px;
}

.timezone button svg {
  font-size: 20px;
  margin-right: -5px !important;
}

/* Added has been removed */
.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 10px;
}

.hiddenLabel {
  display: none;
}

@media (max-width: 1540px) {
  .labelAndPickerContainer {
    display: flex;
    align-items: center;
    gap: 25px;
    /* width: 100%; */
    margin-right: -50px !important;
  }

  .timePickerContainer input {
    margin-left: -25px !important;
  }
}

@media (max-width: 1540px) {
  .labelAndPickerContainer {
    margin-right: -50px !important;
  }
}
