.modal {
  width: 60%;
  padding: 0px 30px;
}

.container {
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;

  height: auto;
  padding: 0px 20px !important;
  color: #243746;
}

.suggestedAddressContainer {
  background-color: #e2e1e1;
  padding: 5px;
  width: 100%;
  height: 100%;

  .modalButtons {
    margin-top: 20px;
  }
}

.userEnteredAddressContainer {
  background-color: #f4f4f4;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .modalButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .editButton {
    order: 2;
  }
}

.addressContainer {
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  margin-top: 20px;
  font-size: 13px;
}

.modalLabel {
  font-size: 18px;
  font-weight: 300;
}

.modalHeader {
  color: #adb7c0;
  font-size: 24px;
  font-weight: 250;
  margin-bottom: 10px;
}

.cancel {
  text-align: center;
  padding: 5px;
  margin: 2px;
}

.modalCancel {
  background-color: #243746;
  height: 40px;
  color: #fff;
  border: 1px solid #243746;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}
.useOriginal {
  background-color: white !important;
}

.loader {
  margin-bottom: 200px;
}

@media (max-width: 1145px) {
  .container {
    min-width: 700px;
  }
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    min-width: 100%;
  }
}


/* Modal */
.overlay {
  pointer-events: all;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalModal {
  min-width: 700px;
  /* max-width: 700px; */
  max-width: 90%;
  width: max-content;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;

  background: var(--color-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-border);
  border-radius: 5px;

  position: absolute;
  top: 30px;

  max-height: calc(100% - 60px);
  outline: none;
}

.header {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 0.5px solid var(--color-border);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  letter-spacing: 0px;

  opacity: 1;
}

.closeButton {
  appearance: none;
  font-size: 1em;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.closeButton:hover:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  background-color: var(--color-text);
  opacity: 0.1;
}

.closeButton > svg {
  stroke: var(--color-button-default);
  color: var(--color-button-text);
  width: 100%;
  height: 100%;
  padding: 5px;
}

.hiddenClose {
  width: 32px;
  height: 32px;
  padding: 5px;
}

.body {
  text-align: left;
  white-space: pre-line;
}

.title {
  font-weight: bold;
}

.exampleStyling label,
input {
  text-align: left;
  font: normal normal 300 10px/12px;
}

.exampleStyling input[type="submit"] {
  background: #f4c579 0% 0% no-repeat padding-box;
}
