.tagContainer {
  background-color: var(--color-bg-accent);
  border-radius: 5px;
  display: flex;
  align-items: center;
  border-style: none;
  user-select: none;
  margin-right: 14px;

  flex-wrap: nowrap;
  max-width: 100%;
}

.tagText {
  text-align: left;
  color: var(--color-button-text);
  opacity: 1;

  margin-right: 10px;

  white-space: normal;
  word-wrap: break-word;
  
  flex: 1 1 auto; 
}

.tagLightCancelButton, .tagDarkCancelButton {
  color: var(--color-button-default) !important;
  cursor: pointer;
  margin-left: 10px;

  flex-shrink: 0;
}
