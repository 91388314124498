.button {
  --background-color: var(--color-button-default);
  --background-color-hover: var(--color-button-default-hover);
  --border-color: var(--color-button-default-border);

  appearance: none;
  height: 36px;
  outline: none;
  border: none;

  font-size: 1em;
  background-color: var(--background-color);
  color: var(--color-button-text);
  padding: 0 0.75em;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid var(--border-color);
  white-space: nowrap;
  position: relative;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button .icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.button .icon > * {
  width: 100%;
  height: 100%;
}

.button:hover {
  background-color: var(--background-color-hover);
}

/* Alternate colors for specific purposes. */

.button.delete {
  --background-color: var(--color-button-delete);
  --background-color-hover: var(--color-button-delete-hover);
  --border-color: var(--color-button-delete-border);
}

.button.save {
  --background-color: var(--color-button-save);
  --background-color-hover: var(--color-button-save-hover);
  --border-color: var(--color-button-save-border);
}

.button:active {
  /* border-bottom: 0; */
  transform: translateY(2px);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button:disabled:hover {
  background-color: var(--background-color);
}

/* Alternate sizes */

.button.small {
  height: 26px;
  font-size: 0.9em;
  border-radius: 5px;
  padding: 0 0.5em;
}

.button.small .icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.button.tiny {
  height: 22px;
  font-size: 0.8em;
  padding: 0.5em 0.5em;
  border-radius: 4px;
}

.button.tiny .icon {
  width: 14px;
  height: 14px;
}

.buttonLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
