.tpContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 112px;
}

.timeIcon {
  color: var(--color-button-text);
  background-color: var(--color-button-default);
  width: 21px;
  height: 23px !important;
  border-radius: 5px;
  /* border-radius: 0.5em; */
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 2px;
  border: transparent;
}

.tpInput {
  height: 100%;
  font-size: 10px;
  padding-left: 1.7rem;
  background: var(--color-input-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
  color: var(--color-input-text);
}

.ampmButtonContainer {
  display: flex;

  position: absolute;
  align-items: center;
  top: 0;
  right: -6.5%;
  bottom: 0;
}

.amButton {
  border: none;
  border-radius: 5px;
  padding: 0.3em 0.15em;
  font-size: 10px;
  color: var(--color-input-text) !important;
}

.pmButton {
  border: none;
  border-radius: 5px;
  padding: 0.3em 0.15em;
  font-size: 10px;
  color: var(--color-input-text) !important;
}

/* Modal */

.modal {
  position: absolute;
  right: 50%;
  top: 15%;
  transform: translate(50%, 0%);
  width: 400px;
  max-width: 100%;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  z-index: 201;
}

.modal svg {
  color: var(--color-button-default);
}

.popOverMenu {
  display: flex;
  align-items: stretch;
  min-width: 350px;
  max-width: 100%;
  background-color: var(--color-form-bg);
  overflow: hidden;
  margin-bottom: 10px;
}

.popOverMenu.open {
  opacity: 1;
  pointer-events: all;
}

.section {
  flex: 0 0 50%;
}

.section.night {
  background-color: var(--color-card-bg);
}

.section > header {
  text-align: center;
  padding: 0.5em;
}

.section > header h3 {
  font-size: 1em;
}

.section ol {
  list-style: none;
}

@media (max-width: 1190px) {
  .ampmButtonContainer {
    right: -6.3%;
  }

  .amButton {
    padding: 0.3em 0.17em;
  }

  .pmButton {
    padding: 0.3em 0.17em;
  }
}
