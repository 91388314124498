.noteFieldContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.inputContainer {
  flex-grow: 1;
}

.noteInput {
  width: 100%;
  padding: 10px 5px;
  color: var(--color-input-text);
  border: 1px solid var(--color-input-border);
  background-color: var(--color-input-bg);
  border-radius: 4px;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;
}

.noteInput::placeholder {
  color: var(--color-input-placeholder);
}

.noteInput:focus {
  border-color: var(--color-input-border-focus);
}

.buttonsContainer {
  display: flex;
}

.cancelButton {
  padding: 10.5px 5px;
  background-color: var(--color-button-white-bg);
  color: var(--color-button-white-text);
  border: 1px solid var(--color-border);
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancelButton:hover {
  background-color: var(--color-button-white-hover);
}

.saveButton {
  padding: 10.5px 11px;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: var(--color-button-default-hover);
}

/* Note Styling */
.noteViewContainer {
  display: flex;
  flex-direction: row;
}

.note {
  background-color: var(--color-note-bg-text);
  display: flex;
  align-items: center;
  height: 32px;
}

.noteBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.noteEditBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  cursor: pointer;
}

.noteBtnContainer:hover {
  cursor: pointer;
}

.noteBtnImage {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.noteBtnText {
  font-size: 14px;
  margin-left: 5px;
  text-decoration: underline;
  color: var(--color-text);
}
