.container {
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  padding-top: 0;
  font-weight: normal;
  font-family: var(--font-family);
}

.headerContainer {
  background: var(--color-bg) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--color-border);
  padding: 1.5rem 0rem 1.5rem 0.5rem;
  position: sticky;
  top: -3%;
  width: 100%;
  z-index: 100;
}

.buttonContainer {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  font-size: 15px;
}

.backButton {
  width: 100%;
  display: flex;
  font-size: 12px;
  cursor: pointer;
}

.undoButton {
  background: var(--color-button-white-bg) 0% 0% no-repeat padding-box;
  color: var(--color-button-white-text);
  border-radius: 5px;
  border: 1px solid var(--color-c);
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 16px;
  height: 32px !important;
}

.undoButton:hover {
  background: var(--color-button-white-hover) !important;
}

.saveButton {
  width: auto !important;
  height: 32px !important;
  background-color: var(--color-button-default);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-default);
  border-radius: 5px;
}

.greenButton {
  width: auto !important;
  height: 32px !important;
  background-color: var(--color-button-save);
  color: var(--color-button-text);
  border: 1px solid var(--color-button-save-border);
  border-radius: 5px;
}

.bodyContainer {
  padding: 1.5rem 0.5rem;
}

.cardContainer {
  margin: 2em 0;
}

.footerContainer {
  padding: 0 0.5rem 1.5rem 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.cardHeader {
  font-weight: bold;
}

.title {
  font-size: 1.2em;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.loaderText {
  display: flex;
  font-weight: bold;
  color: var(--color-text-accent);
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1rem;
  position: relative;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
