.containerOriginal {
    padding: 10px 10px 0px 10px;
    background-color: #F4F4F4;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.containerSuggested {
    padding: 10px 10px 0px 10px;
    background-color: #e2e1e1;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    color: #3f7a81;
    font-size: 20px;
    font-weight: 250;
    margin-bottom: 10px;
    padding-left: 22px;
}

.address {
    background-color: white;
    padding: 20px;
    text-align: start;
    border-radius: 5px;
}

.bottomRow {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0px 0px;
    text-align: start;
}

.buttonEdit{
    padding-left: 20px;
    cursor: pointer;
    align-self: center;
}

.useSuggestedButton {
  background-color: #91B6BB;
  color: #fff;
  border: 1px solid #91B6BB;
  font-size: 14px;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.useSuggestedButton:hover {
  background-color: #fff;
  color: #91B6BB;
  border-color: #91B6BB;
}

.useAddressButton {
  background-color: #F4F4F4;
  color: #243746;
  border: 1px solid #243746;
  font-size: 14px;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.useAddressButton:hover {
  background-color: #243746;
  color: #F4F4F4;
  border-color: #F4F4F4;
}
