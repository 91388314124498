.modal {
  position: absolute;
  right: 50%;
  top: 15%;
  transform: translate(50%, 0%);
  width: 650px;
  border: 3px solid var(--color-border);
  border-radius: 6px;
  padding: 20px;
  background-color: var(--color-card-bg);
  font-weight: lighter;
  font-family: var(--font-family);
  z-index: 201;
  padding-bottom: 25px;
}

.title {
  font-weight: bold;
  font-size: 15px;
}

.searchContainer {
  margin-top: 20px;
  margin-bottom: 15px;
  outline: 3px solid var(--color-input-border);
  border-radius: 8px;
  padding: 18px;
  font-size: 15px;
}

.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.searchBox input {
  height: 32px;
  width: 400px;
  border-radius: 8px;
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  border: 3px solid var(--color-input-border);
}

.searchBox input::placeholder {
  color: var(--color-input-placeholder);
}

.searchBox input:focus {
  border: 3px solid var(--color-input-border-focus);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 30px;
  width: 130px;
}

.searchButton {
  font-size: 15px;
  border-radius: 8px;
  background-color: var(--color-button-accent);
  border: none;
  height: 30px;
  font-weight: bold;
}

.losContainer {
  margin-top: 10px;
  border: 3px solid var(--color-border);
  border-radius: 5px;
  padding: 18px;
}

.losBox {
  display: grid;
  grid-template-columns: 52% auto;
  width: 100%;
  font-size: 15px;
}

.grid {
  display: grid;
  grid-template-columns: 19% 35% 16% auto;
  width: 100%;
  font-size: 15px;
}

.item {
  margin-bottom: 10px;
  font-weight: bold;
}

.value {
  margin-bottom: -10px;
  word-wrap: break-word;
  max-width: 180px;
  padding-bottom: 10px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.addButton {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 15px;
  margin-top: 25px;
}

.addButton button {
  background: var(--color-button-default) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  height: 32px;
  width: 75px;
}

.gray {
  color: var(--color-text-gray);
}

.radioButtonList {
  padding-top: 10px;
}
