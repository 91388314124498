.container {
  width: 100%;
  height: auto;
  color: var(--color-text);
  border: 3px solid var(--color-border);
  border-radius: 12px;
  background-color: var(--color-card-bg);
  padding: 18px;
  padding-left: 17px;
  padding-right: 17px;
  /* font-size: 1em; */
  font-size: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
}

.fontsize {
  /* font-size: 1em; */
  font-size: 16px;
}

.item {
  /* font-size: 1em; */
  font-size: 15px;
}

.cardHeader {
  font-weight: bold;
  width: 50%;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
  border: 0.5px solid var(--color-border);
  margin-top: 10px;
  margin-bottom: 20px;
}

.changeBtn {
  background-color: var(--color-button-white-bg);
  color: var(--color-button-white-text);
  border: 1px solid var(--color-button-white-border);
  padding-bottom: 2px !important;
  cursor: pointer;
  height: 27px !important;
  padding: 3px;
  border-radius: 5px;
}

.changeBtn:hover {
  background-color: var(--color-button-white-hover);
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;
}

.grid > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid button {
  min-width: 130px;
}

.columns {
  font-weight: bold;
  margin-right: 25px;
}

@media (max-width: 1280px) {
  .grid > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .grid button {
    min-width: 100px;
  }
}
